// CompanyData.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Ico from '../../assets/Lfather.png';
import '../../../../tailwind.css';
import FiscalClassSelect from '../Item/fiscalClass';
import CountrySelect from './countrySelect';

function CompanyData({ onNext, companyData, setCompanyData, typeID }) {
    const { t } = useTranslation();

    console.log('typeID:', typeID); // Verifique o valor aqui

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompanyData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFiscalClassChange = (value) => {
        setCompanyData(prevData => ({
            ...prevData,
            fiscalClass: value
        }));
    };

    return (
        <section className='center-comp'>
            <img src={Ico} className='Ico' alt='Icon' />
            <h4 className='icoText'>{t('register')}</h4>
            <div>
                <form className='formdataRegisterInpt formLarge'>
                    <label className='lbInpt'>{t('company_name')}</label>
                    <input
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder={t('enter_name_company')}
                        type='text'
                        name='companyName'
                        value={companyData.companyName || ''}
                        onChange={handleInputChange}
                    />

                    <label className='lbInpt'>{t('corporate_name')}</label>
                    <input
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder={t('enter_corporate_name')}
                        type='text'
                        name='corporateName'
                        value={companyData.corporateName || ''}
                        onChange={handleInputChange}
                    />
                   
                    {typeID === 'CNPJ' && (
                        <>
                            <label className='lbInpt'>{t('fiscal_class')}</label>
                            <FiscalClassSelect
                                selectedFiscalClass={companyData.fiscalClass}
                                onChange={handleFiscalClassChange}
                            />

                            <label className='lbInpt'>{t('state_registration')}</label>
                            <input
                                className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                                placeholder={t('enter_state_registration')}
                                type='text'
                                name='stateRegistration'
                                value={companyData.stateRegistration || ''}
                                onChange={handleInputChange}
                            />
                        </>
                    )}
                </form>
                <button className='action' onClick={onNext}>
                    <p>{t('next')}</p>
                </button>
            </div>
        </section>
    );
}

export default CompanyData;
