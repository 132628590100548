import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Import the useNavigate hook
import '../../Components/Buyer/marketPlace.css'; // Assuming you have specific styles for MarketPlace

function MarketPlace({ roundKey }) {
    const [products, setProducts] = useState([]);  // State to store the fetched products
    const [loading, setLoading] = useState(true);  // State to handle the loading state
    const [error, setError] = useState(null);      // State to handle errors
    const navigate = useNavigate();  // Hook to navigate between routes

    // Fetch products from the API when the component mounts
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                // Get the companyID from localStorage if needed for filtering
                const companyID = localStorage.getItem('selectedCompanyID');

                // Make an API call with the roundKey and companyID as query parameters if needed
                const response = await axios.get('https://api.father.srv.br/api/v2/products/products-by-roundkey', {
                    params: { roundKey: roundKey, companyID: companyID }  // Pass roundKey and companyID as query parameters
                });
                setProducts(response.data);  // Store the fetched products in the state
            } catch (err) {
                setError('Erro ao carregar os produtos: ' + err.message); // Handle errors
            } finally {
                setLoading(false);  // Stop loading after the request completes
            }
        };

        if (roundKey) {
            fetchProducts();
        } else {
            setError('RoundKey não encontrado.');
            setLoading(false);
        }
    }, [roundKey]);  // Dependency array includes roundKey to refetch if it changes

    if (loading) {
        return <p>Carregando produtos...</p>;  // Show loading message while fetching data
    }

    if (error) {
        return <p>{error}</p>;  // Show error message if the request fails
    }

    // Function to truncate text to a specified length and add ellipsis
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    // Function to handle product click and navigate to the product detail page
    const handleProductClick = (productId) => {
        navigate(`/product/${productId}`);  // Redirect to product detail page
    };

    return (
        <section className='boxProducts'>
            <div className='gridProducts'>
                {products.length > 0 ? (
                    products.map(product => (
                        <div 
                            key={product._id} 
                            className='products' 
                            onClick={() => handleProductClick(product._id)}  // Add onClick to redirect on click
                        >
                            <div className='productData'>
                                <h4>{truncateText(product.productName, 15)}</h4>
                                <div className='line'></div>
                                <label className='lbProduct'>Preço</label>
                                <h5 id='price'>{product.cashType} {product.unitValue} </h5>
                                <label className='lbProduct'>Origem</label>
                                {/* Display country, city, and state */}
                                <h5>{product.country}</h5>
                                <label id='showMore' className='lbProduct'>Ver mais...</label>
                            </div>
                            <div className='img'>
                                <img src={product.photoUrls[0]} alt={product.productName} />
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Nenhum produto encontrado.</p>
                )}
            </div>
        </section>
    );
}

export default MarketPlace;
