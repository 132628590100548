import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import MenuRounds from '../Layout/menu';
import '../Buyer/agend.css';
import FooterRounds from '../Layout/footer';

function Agend() {
    const [tradeCalendars, setTradeCalendars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentDay, setCurrentDay] = useState(null);
    const [days, setDays] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const companyID = localStorage.getItem('selectedCompanyID');
    const userCompanyID = localStorage.getItem('userCompanyID');
    
    const location = useLocation();
    const { productID } = location.state || {}; // Recuperar o productID do state passado

    useEffect(() => {
        const fetchTradeCalendars = async () => {
            try {
                if (!companyID) {
                    throw new Error('CompanyID não encontrado.');
                }

                const response = await axios.get(`https://api.father.srv.br/api/v2/agends/trade-calendar/${companyID}`);
                setTradeCalendars(response.data);

                const daysSet = new Set(response.data.flatMap(calendar => calendar.schedule.map(item => item.day)));
                setDays([...daysSet].sort());

                if (daysSet.size > 0) {
                    setCurrentDay([...daysSet][0]);
                }
            } catch (err) {
                setError('Erro ao carregar o calendário de negócios: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTradeCalendars();
    }, [companyID]);

    const handlePreviousDay = () => {
        const currentIndex = days.indexOf(currentDay);
        if (currentIndex > 0) {
            setCurrentDay(days[currentIndex - 1]);
        }
    };

    const handleNextDay = () => {
        const currentIndex = days.indexOf(currentDay);
        if (currentIndex < days.length - 1) {
            setCurrentDay(days[currentIndex + 1]);
        }
    };

    const handleSelectSchedule = (schedule) => {
        setSelectedSchedule(schedule);
    };

    const handleConfirmSchedule = async () => {
        if (!selectedSchedule || !productID) return;

        try {
            await axios.put(`https://api.father.srv.br/api/v2/agends/update-schedule`, {
                companyID,
                userCompanyID,
                day: selectedSchedule.day,
                start: selectedSchedule.start,
                end: selectedSchedule.end,
                productID, // Incluindo o productID no request
            });

            alert('Horário confirmado com sucesso!');
            setSelectedSchedule(null);
        } catch (err) {
            alert('Erro ao confirmar o horário: ' + err.message);
        }
    };

    const filteredSchedules = tradeCalendars.flatMap(calendar =>
        calendar.schedule.filter(item => item.day === currentDay)
    );

    if (loading) {
        return <p>Carregando calendário...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (filteredSchedules.length === 0) {
        return <p>Nenhum horário encontrado para o dia selecionado.</p>;
    }


    return (
        <section>
            <section className='menuContent'>
                <MenuRounds />
                <div className='boxCalendar'>
                    <h2>Selecione o horário</h2>
                    <p>Verifique os horários disponíveis</p>
                    <div className='handleDay'>
                        <div onClick={handlePreviousDay} disabled={!currentDay || days.indexOf(currentDay) === 0}>
                            <svg width="10" height="30" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.08333 14.5834L0 7.50008L7.08333 0.416748L8.34063 1.67404L2.51458 7.50008L8.34063 13.3261L7.08333 14.5834Z" fill="#1C1B1F" />
                            </svg>
                        </div>
                        <p>{currentDay}</p>
                        <div onClick={handleNextDay} disabled={!currentDay || days.indexOf(currentDay) === days.length - 1}>
                            <svg width="10" height="30" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.75 12.75L8 6.5L1.75 0.25L0.640625 1.35937L5.78125 6.5L0.640625 11.6406L1.75 12.75Z" fill="#1C1B1F" />
                            </svg>
                        </div>
                    </div>
                    <ul className='boxTimes'>
                        {filteredSchedules.map((item, idx) => (
                            <div
                                className={`time ${item.available ? 'visible' : 'hidden'} ${selectedSchedule === item ? 'selected' : ''}`}
                                key={idx}
                                onClick={() => item.available && handleSelectSchedule(item)} // Só permite seleção se estiver disponível
                            >
                                {item.start} às {item.end} - {item.day}
                            </div>
                        ))}
                    </ul>

                    <div className='action agendar' onClick={handleConfirmSchedule} disabled={!selectedSchedule}>
                        <p>Confirmar</p>
                    </div>
                </div>
            </section>
            <FooterRounds />
        </section>
    );
}

export default Agend;
