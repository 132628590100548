import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Sucess/sucess.css';
import Ico from '../../assets/Lfather.png';
import '../KeyRounds/KeyRounds.css';

function KeyRounds() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [keyRound, setKeyRound] = useState(''); // Estado para a chave do round
    const [errorMessage, setErrorMessage] = useState(''); // Estado para a mensagem de erro
    const [successMessage, setSuccessMessage] = useState(''); // Estado para a mensagem de sucesso

    // Recupera o email do localStorage
    const email = localStorage.getItem('userEmail');

    // Função para lidar com a mudança de valor do input
    const handleInputChange = (event) => {
        setKeyRound(event.target.value);
    };

    // Função para verificar se a chave do round existe e adicionar ao usuário
    const handleRoundKeySubmit = async () => {
        if (!email) {
            setErrorMessage(t('email_not_found'));
            return;
        }

        try {
            const checkResponse = await axios.get(`https://api.father.srv.br/api/v2/rounds/exists/${keyRound}`);

            if (checkResponse.data.exists) {
                const addResponse = await axios.post(`https://api.father.srv.br/api/v2/rounds/add-round/${email}`, { keyRound });
                if (addResponse.status === 200) {
                    setSuccessMessage(t('Round adicionado com sucesso.'));
                    setErrorMessage('');
                    navigate('/rounds/home'); // Redireciona para a rota /rounds/home
                }
            } else {
                setErrorMessage(t('A chave digitada não existe.'));
                setSuccessMessage('');
            }
        } catch (error) {
            console.error('Erro ao processar a chave do round:', error);
            setErrorMessage(t('Round já adicionado'));
            setSuccessMessage('');
        }
    };

    return (
        <section>
            <div className='closeBtt'>
                
              <Link to='/rounds/home'> <svg width="28" height="29" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M18.1562 5.99731L6.15625 17.9973" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.15625 5.99731L18.1562 17.9973" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg></Link> 
            </div>
            <div className='SucessCenter'>

                <div className='lineIngress'>
                    <img src={Ico} className='IcoSmall' alt='Logo' />
                    <h3>{t('join_round')}</h3>
                </div>
                <div className='inptKey'>
                    <label className='lbInpt'>{t('enter_round_key')}</label>
                    <input
                        placeholder='XXX/XXX/XXXX-XX'
                        className='bg-bgInpt inptkeyW font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        value={keyRound} // Conecta o estado ao valor do input
                        onChange={handleInputChange} // Atualiza o estado quando o usuário digita
                    />
                </div>
                {errorMessage && <p className='validInfos'>{errorMessage}</p>} {/* Exibe a mensagem de erro, se houver */}
                {successMessage && <p className='validInfos'>{successMessage}</p>} {/* Exibe a mensagem de sucesso, se houver */}
                <button className='action ctaKey' onClick={handleRoundKeySubmit}>
                    <p>Entrar na rodada</p>
                </button>
            </div>
        </section>
    );
}

export default KeyRounds;
