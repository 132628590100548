import React, { useState, useEffect } from 'react';
import addressData from '../../../../db/adressData.json';

function CitySelect({ setCompanyData, selectedState }) {
    const [inputValue, setInputValue] = useState('');
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [allCities, setAllCities] = useState([]); // Lista completa de cidades
    const [filteredCities, setFilteredCities] = useState([]);

    useEffect(() => {
        if (selectedState) {
            const stateData = addressData.estados.find(estado => estado.sigla === selectedState);
            if (stateData) {
                setAllCities(stateData.cidades); // Define a lista completa de cidades
                setFilteredCities(stateData.cidades); // Inicializa a lista filtrada com todas as cidades
            } else {
                setAllCities([]);
                setFilteredCities([]);
            }
        } else {
            setAllCities([]);
            setFilteredCities([]);
        }
    }, [selectedState]);

    const handleOptionSelect = (city) => {
        setInputValue(city);
        setIsOptionsVisible(false);
        setCompanyData(prevData => ({
            ...prevData,
            city: city // Atualiza o estado com a cidade selecionada
        }));
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setIsOptionsVisible(true);
        const filtered = allCities.filter(city =>
            city.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCities(filtered);
    };

    return (
        <section id='minBox' style={{ position: 'relative' }}>
            <section id='minBox category-select'>
                <div className='minBox'>
                    <div id='chevrons'></div>
                    <input
                        type='text'
                        className='medBox bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder="Select City"
                        value={inputValue}
                        onChange={handleInputChange}
                        onClick={() => setIsOptionsVisible(!isOptionsVisible)}
                        onFocus={() => setIsOptionsVisible(true)}
                    />
                </div>
            </section>
            <ul
                id="options"
                style={{
                    display: isOptionsVisible ? 'block' : 'none',
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    zIndex: 10,
                    backgroundColor: '#F3F3F3',
                    width: '11rem',
                    maxHeight: '200px',
                    overflowY: 'auto'
                }}
            >
                {filteredCities.length > 0 ? (
                    filteredCities.map((city) => (
                        <li
                            key={city}
                            className="medBox option"
                            onClick={() => handleOptionSelect(city)}
                        >
                            <span className='label'>{city}</span>
                        </li>
                    ))
                ) : (
                    <li className="option">
                        <span className='label'>Cidade não encontrada.</span>
                    </li>
                )}
            </ul>
        </section>
    );
}

export default CitySelect;
