import React, { useState } from 'react';
import TypeSelect from "../pages/Rounds/Components/FormRegister/typeSelect";
import CheckPJ from "../pages/Rounds/Components/FormRegister/checkPJ";
import CompanyData from "../pages/Rounds/Components/FormRegister/companyData";
import CompanyComplet from '../pages/Rounds/Components/FormRegister/companyComplet';
import BuyerCheck from '../pages/Rounds/Components/FormRegister/buyerCheck';
import CheckMail from '../pages/Rounds/Components/FormRegister/mailCheck';
import ContactDataCreate from '../pages/Rounds/Components/FormRegister/contactCreate';
import PasswordCreate from '../pages/Rounds/Components/FormRegister/passwordCreate';

function RegisterService() {
  const [currentStep, setCurrentStep] = useState(0); // Gerencia o passo atual no fluxo
  const [userType, setUserType] = useState(""); // Armazena o tipo de usuário selecionado (supplier ou buyer)
  const [companyID, setCompanyID] = useState(""); // Armazena o ID da empresa
  const [companyIDExists, setCompanyIDExists] = useState(null); // Verifica se a empresa já existe
  const [segment, setSegment] = useState(""); // Armazena o segmento
  const [subsegment, setSubsegment] = useState(""); // Armazena o subsegmento
  const [emailExists, setEmailExists] = useState(null); // Verifica se o email já está registrado
  const [companyData, setCompanyData] = useState({
    companyName: '',
    corporateName: '',
    fiscalClass: '',
    stateRegistration: '',
    state: '',
    city: '',
    fullAddress: '',
    segment: '',
    subsegment: '',
    typeID: ''  // Adiciona o typeID aqui
  });

  const [contactData, setContactData] = useState({
    name: '',
    phone: '',
    email: '',
    role: '',
    sector: ''
  });

  // Função que avança para o próximo passo
  const handleNextStep = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

    // Função que lida com a seleção de país
    const handleCountrySelect = (selectedCountry) => {
      setCompanyData(prevData => ({ ...prevData, country: selectedCountry })); // Atualiza o country no companyData
    };

  // Função chamada quando o tipo de usuário é selecionado (supplier ou buyer)
  const handleTypeSelect = (selectedType, typeID) => {
    setUserType(selectedType); // Atualiza o tipo de usuário
    setCompanyData(prevData => ({ ...prevData, typeID })); // Atualiza o typeID no companyData
    // Avança para o passo 1 se for supplier, ou mantém no passo 0 se for buyer
    setCurrentStep(selectedType === 'supplier' ? 1 : 0);
  };

  // Função que lida com a mudança de tipo de documento no BuyerCheck
  const handleDocumentTypeChange = (type) => {
    setCompanyData(prevData => ({ ...prevData, typeID: type })); // Atualiza o typeID
  };

  // Função que lida com a seleção de segmento
  const handleSegmentSelect = (selectedSegment) => {
    setSegment(selectedSegment);
    setCompanyData(prevData => ({ ...prevData, segment: selectedSegment }));
  };

  // Função que lida com a seleção de subsegmento
  const handleSubSegmentSelect = (selectedSubsegment) => {
    setSubsegment(selectedSubsegment);
    setCompanyData(prevData => ({ ...prevData, subsegment: selectedSubsegment }));
  };

  // Função que retorna o componente correto para cada passo, baseado no tipo de usuário
  const getStepScreen = () => {
    if (userType === 'supplier') {
      // Fluxo para supplier (fornecedor)
      switch (currentStep) {
        case 1:
          return (
            <CheckPJ
              onNext={handleNextStep}
              setCompanyID={setCompanyID}
              setCurrentStep={setCurrentStep}
              setCompanyIDExists={setCompanyIDExists}
              typeID={companyData.typeID}  // Passa o typeID para CheckPJ
            />
          );
        case 2:
          return (
            <CompanyData
              onNext={handleNextStep}
              companyData={companyData}
              setCompanyData={setCompanyData}
              typeID={companyData.typeID}  // Passa o typeID
            />
          );
        case 3:
          return (
            <CompanyComplet
              onNext={handleNextStep}
              companyData={companyData}
              setCompanyData={setCompanyData}
              onSegmentSelect={handleSegmentSelect}
              onSubSegmentSelect={handleSubSegmentSelect}
              onCountrySelect={handleCountrySelect} // Passa a função para selecionar país
            />
          );
        case 4:
          return (
            <CheckMail
              onNext={handleNextStep}
              setContactData={setContactData}
              contactData={contactData}
              setCurrentStep={setCurrentStep}
              setEmailExists={setEmailExists}
            />
          );
        case 5:
          return (
            <ContactDataCreate
              onNext={handleNextStep}
              setContactData={setContactData}
              contactData={contactData}
            />
          );
        case 6:
          return (
            <PasswordCreate
              setContactData={setContactData}
              onNext={handleNextStep}
              companyID={companyID}
              contactData={contactData}
              emailExists={emailExists}
              companyIDExists={companyIDExists}
              userType={userType}
              companyData={companyData}
            />
          );
        default:
          return (
            <section>
              <h3>Company Data</h3><br />
              <p>Company Name: {companyData.companyName}</p>
              <p>Company ID: {companyID}</p>
              <p>Corporate Name: {companyData.corporateName}</p>
              <p>Fiscal Class: {companyData.fiscalClass}</p>
              <p>State Registration: {companyData.stateRegistration}</p>
              <br />
              <p>Segment: {segment}</p>
              <p>Subsegment: {subsegment}</p>
              <p>State: {companyData.state}</p>
              <p>Full Address: {companyData.fullAddress}</p>
            </section>
          );
      }
    } else if (userType === 'buyer') {
      // Fluxo para buyer (comprador)
      switch (currentStep) {
        case 0:
          return (
            <BuyerCheck
              onNext={handleNextStep}
              setCompanyIDExists={setCompanyIDExists}
              setCompanyID={setCompanyID} // Atualiza o companyID no estado de RegisterService
              onDocumentTypeChange={handleDocumentTypeChange} // Atualiza o typeID
            />
          );
        case 1:
          return (
            <CompanyData
              onNext={handleNextStep}
              companyData={companyData}
              setCompanyData={setCompanyData}
              typeID={companyData.typeID}  // Passa o typeID para CompanyData
            />
          );
          case 2: 
          return(
            <CompanyComplet
            onNext={handleNextStep}
            companyData={companyData}
            setCompanyData={setCompanyData}
            onSegmentSelect={handleSegmentSelect}
            onSubSegmentSelect={handleSubSegmentSelect}
            typeID={companyData.typeID} 
            onCountrySelect={handleCountrySelect} // Passa a função para selecionar país // Passa o typeID para controlar a renderização
          />
          )

          case 3:
          return (
            <CheckMail
              onNext={handleNextStep}
              setContactData={setContactData}
              contactData={contactData}
              setCurrentStep={setCurrentStep}
              setEmailExists={setEmailExists}
            />
          );
        case 5:
          return (
            <ContactDataCreate
              onNext={handleNextStep}
              setContactData={setContactData}
              contactData={contactData}
            />
          );
        case 6:
          return (
            <PasswordCreate
              setContactData={setContactData}
              onNext={handleNextStep}
              companyID={companyID}
              contactData={contactData}
              emailExists={emailExists}
              companyIDExists={companyIDExists}
              userType={userType}
              companyData={companyData}
            />
          );
        default:
          return <h1>Registro Completo!</h1>;
      }
    } else {
      // Passo inicial, onde o usuário escolhe se é "supplier" ou "buyer"
      return (
        <TypeSelect
          onSubmit={handleTypeSelect} // Função que define o tipo de usuário e o typeID
          registerServiceControl={{
            setTypeID: (id) => setCompanyData(prevData => ({ ...prevData, typeID: id })),
          }}
        />
      );
    }
  };

  return (
    <div>
      {getStepScreen()} {/* Exibe o componente do passo atual */}
    </div>
  );
}

export default RegisterService;