import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importando traduções
import enTranslation from './locales/en/translation.json';
import ptTranslation from './locales/pt/translation.json';
import esTranslation from './locales/es/translation.json';

const resources = {
  en: {
    translation: enTranslation
  },
  pt: {
    translation: ptTranslation
  },
  es: {
    translation: esTranslation
  }
};

i18n
  .use(initReactI18next) // passa i18n para o react-i18next
  .init({
    resources,
    lng: 'pt', // idioma padrão
    fallbackLng: 'pt', // idioma de fallback
    interpolation: {
      escapeValue: false, // react já faz a proteção contra XSS
    },
  });

export default i18n;
