import React, { useState } from 'react';
import addressData from '../../../../db/adressData.json';

function StateSelect({ onChange }) {
    const [inputValue, setInputValue] = useState('');
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [filteredStates, setFilteredStates] = useState(addressData.estados);

    const handleOptionSelect = (uf) => {
        setInputValue(uf);
        setIsOptionsVisible(false);
        if (onChange) onChange(uf); // Notifica o pai sobre a alteração do estado
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setIsOptionsVisible(true);
        const filtered = addressData.estados.filter(estado =>
            estado.sigla.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredStates(filtered);
    };

    return (
        <section id='minBox' style={{ position: 'relative' }}>
            <section id='minBox category-select'>
                <div className='minBox'>
                    <div id='chevrons'></div>
                    <input
                        type='text'
                        className='minBox bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder="Select State"
                        value={inputValue}
                        onChange={handleInputChange}
                        onClick={() => setIsOptionsVisible(!isOptionsVisible)}
                        onFocus={() => setIsOptionsVisible(true)}
                    />
                </div>
            </section>
            <ul
                id="options"
                style={{
                    display: isOptionsVisible ? 'block' : 'none',
                    position: 'absolute', 
                    top: '100%', 
                    left: '0',
                    zIndex: 10, 
                    backgroundColor: '#F3F3F3',
                    width: '5.5rem', 
                    maxHeight: '200px', 
                    overflowY: 'auto' 
                }}
            >
                {filteredStates.length > 0 ? (
                    filteredStates.map((estado) => (
                        <li 
                            key={estado.sigla} 
                            className="minBox option" 
                            onClick={() => handleOptionSelect(estado.sigla)}
                        >
                            <span className='label'>{estado.sigla}</span>
                        </li>
                    ))
                ) : (
                    <li className="option">
                        <span className='label'>No states found.</span>
                    </li>
                )}
            </ul>
        </section>
    );
}

export default StateSelect;
