import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Ico from '../../assets/Lfather.png';
import '../../../../tailwind.css';
import PositionSelect from '../Item/position';
import SectorSelect from '../Item/sector';

function ContactDataCreate({ onNext, setContactData }) {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [sector, setSector] = useState('');

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        setContactData(prevData => ({
            ...prevData,
            name: newName
        }));
    };

    const handlePhoneChange = (e) => {
        const newPhone = e.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        setPhone(newPhone);
        setContactData(prevData => ({
            ...prevData,
            phone: newPhone
        }));
    };

    const handleSectorChange = (newSector) => {
        setSector(newSector);
        setContactData(prevData => ({
            ...prevData,
            sector: newSector
        }));
    };

    const handlePositionChange = (newPosition) => {
        setContactData(prevData => ({
            ...prevData,
            role: newPosition // Aqui atualizamos 'role' com a posição selecionada
        }));
    };

    return (
        <section className='center-comp'>
            <img src={Ico} className='Ico' alt='Icon' />
            <h4 className='icoText'>{t('register')}</h4>
            <div>
                <form className='formdataRegisterInpt formLarge'>
                    <label className='lbInpt'>{t('full_name')}</label>
                    <input
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder={t('full_name')}
                        type='text'
                        value={name}
                        onChange={handleNameChange}
                    />

                    <label className='lbInpt'>{t('phone')}</label>
                    <input
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder={t('phone')}
                        type='text'
                        value={phone}
                        onChange={handlePhoneChange}
                    />

                    <label className='lbInpt'>{t('sector')}</label>
                    <SectorSelect onChange={handleSectorChange} />

                    <label className='lbInpt'>{t('position')}</label>
                    <PositionSelect onChange={handlePositionChange} />
                    
                </form>
                <button
                        className='action'
                        onClick={() => onNext()}
                        disabled={!(name && phone && sector)}
                    >
                        <p>{t('next')}</p>
                </button>
            </div>

        </section>
    );
}

export default ContactDataCreate;
