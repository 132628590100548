import React, { useEffect, useState } from 'react';
import MenuRounds from "../../Components/Layout/menu";
import FooterRounds from "../../Components/Layout/footer";
import "../Calendar/calendar.css";
import axios from 'axios';

function Calendar() {
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    // Obter o companyID e userType do local storage
    const userCompanyID = localStorage.getItem('userCompanyID');
    const userType = localStorage.getItem('userType');

    // Fazer a requisição para buscar os horários específicos
    axios.get(`https://api.father.srv.br/api/v2/agends/trade-calendar/user`, {
      params: { companyID: userCompanyID, userType: userType }
    })
      .then(response => {
        setSchedules(response.data);
      })
      .catch(error => {
        console.error('Erro ao buscar os horários:', error);
      });
  }, []);

  return (
    <section>
      <section className="menuContent">
        <MenuRounds />
        <div>
          <div className='gridCalendars'>
            {schedules.length > 0 ? (
              schedules.map((schedule, index) => (
                <div key={index} className="boxCalendarAgend">
                  <div className='infosAgend'>
                    <div className='cancelAgend'>
                      <svg className="svgIcon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="svgIcon" d="M8.60898 17.2405L7.20898 15.8405L10.809 12.2405L7.20898 8.6655L8.60898 7.2655L12.209 10.8655L15.784 7.2655L17.184 8.6655L13.584 12.2405L17.184 15.8405L15.784 17.2405L12.209 13.6405L8.60898 17.2405Z" fill="#ACACAC" />
                      </svg>
                    </div>
                    <label className='lbInpt pddLb'>{schedule.day}</label>
                    <h2>{schedule.start} às {schedule.end}</h2>

                    {localStorage.getItem('userType') === 'buyer' ? (
                      <p>{schedule.companyName}</p>
                    ) : (
                      <p>Reunião {index + 1}</p>
                    )}

                  </div>
                </div>
              ))
            ) : (
              <p>Nenhuma agenda encontrada.</p>
            )}
          </div>
        </div>
      </section>
      <FooterRounds />
    </section>
  );
}

export default Calendar;
