import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Sucess/sucess.css';
import Ico from '../../assets/Lfather.png';


function Sucess() {
    const { t } = useTranslation();

    return (
        <section>
            <div className='SucessCenter'>
                <img src={Ico} className='IcoSmall'/>
                <h3>Sucesso</h3>
                <p>{t('account_created')}</p>
                <button className='action ctaSmall'>
                    <p>{t('start_now')}</p>
                </button>
            </div>
        </section>
    );
}

export default Sucess;
