import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from '../../../Components/Banner/banner'
import FormLogin from '../../../Components/FormLogin/formLogin'
import '../../LoginRegister/Login/LoginRegister.css'
import TranslateButton from '../../../Components/Translate/button'


function Login() {

  return (
    <section className='LoginPage'>

      <section>
        <Banner className='banner'/>
      </section>
      

      <section className='FormCenter'>
      <div className='translate'><TranslateButton /></div>
        <div className='FormLogin'>
          <FormLogin />
        </div>
      </section>


    </section>
  );
}

export default Login;
