import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next';
import DocumentTypeSelect from '../Item/documentType';
import { getDocumentMaskAndValidation } from '../../../../services/documentService';
import axios from 'axios';
import Ico from '../../assets/Lfather.png';

function BuyerCheck({ onNext, onDocumentTypeChange, setCompanyID }) { // Adiciona setCompanyID para propagar o companyID
    const { t } = useTranslation();
    const [documentType, setDocumentType] = useState(''); // Estado local para documentType
    const [documentInput, setDocumentInput] = useState('');
    const [isDocumentValid, setIsDocumentValid] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDocumentInput('');
        setIsDocumentValid(true);
    }, [documentType]);

    const handleInputChange = (e) => {
        const { mask } = getDocumentMaskAndValidation(documentType);
        const value = mask(e.target.value);
        setDocumentInput(value);
    };

    const handleDocumentTypeChange = (selectedType) => {
        setDocumentType(selectedType);
        onDocumentTypeChange(selectedType); // Atualiza o typeID no RegisterService
    };

    const handleNextClick = async () => {
        const { validate } = getDocumentMaskAndValidation(documentType);
        const isValid = validate(documentInput);

        setIsDocumentValid(isValid);
        if (isValid) {
            setLoading(true);
            try {
                const response = await axios.get('https://api.father.srv.br/api/v2/companies/exists', {
                    params: { companyID: documentInput, typeID: documentType }
                });

                const companyExists = response.data.exists;
                setCompanyID(documentInput); // Atualiza o companyID no RegisterService
                
                setLoading(false);
                onNext(); // Avança para o próximo passo
            } catch (err) {
                setLoading(false);
                setCompanyID(documentInput); // Mesmo em caso de erro, atualiza o companyID
                onNext(); // Avança para o próximo passo
            }
        }
    };

    return (
        <section className='center-comp'>
            <img src={Ico} className='Ico' alt='Icon' />
            <h4 className='icoText'>{t('register')}</h4>
            <div>
                <form className='formdataRegisterInpt'>
                    <label className='lbInpt'>{t('fiscal_document_type')}</label>
                    <DocumentTypeSelect onDocumentTypeChange={handleDocumentTypeChange} />
                    <label className='lbInpt'>{t('document_number')}</label>
                    <input
                        className={`bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert ${!isDocumentValid ? 'border-red-500' : ''}` }
                        placeholder={t('enter_document_number')}
                        type='text'
                        value={documentInput}
                        onChange={handleInputChange}
                    />
                    {!isDocumentValid && <p className="validInfos">{t('invalid_document')}</p>}
                </form>
                <button className='action' onClick={handleNextClick}>
                    <p>{t('next')}</p>
                </button>
                {loading && <div className='loading-circle'></div>}
            </div>
        </section>
    );
}

export default BuyerCheck;
