// services/documentService.js

// Máscara para CNPJ
export const applyCnpjMask = (value) => {
    return value
        .replace(/\D/g, '') // Remove tudo que não for dígito
        .replace(/^(\d{2})(\d)/, '$1.$2') // Adiciona o primeiro ponto
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Adiciona o segundo ponto
        .replace(/\.(\d{3})(\d)/, '.$1/$2') // Adiciona a barra
        .replace(/(\d{4})(\d)/, '$1-$2') // Adiciona o traço
        .substring(0, 18); // Limita ao tamanho máximo de um CNPJ
};

// Validação de CNPJ
export const isValidCnpj = (cnpj) => {
    cnpj = cnpj.replace(/\D/g, '');
    if (cnpj.length !== 14) return false;
    if (/^(\d)\1*$/.test(cnpj)) return false;
    let size = cnpj.length - 2, numbers = cnpj.substring(0, size), digits = cnpj.substring(size), sum = 0, pos = size - 7;
    for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;
    size++;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
};

// Máscaras para outros tipos de documentos
export const applyRucMask = (value) => value.replace(/\D/g, '').substring(0, 13);
export const applyTaxIdMask = (value) => value.replace(/\D/g, '').substring(0, 15);
export const applyRutMask = (value) => value.replace(/\D/g, '').substring(0, 12);
export const applyNitMask = (value) => value.replace(/\D/g, '').substring(0, 14);
export const applyNifMask = (value) => value.replace(/\D/g, '').substring(0, 9);

// Validações para outros tipos de documentos
export const isValidRuc = (ruc) => ruc.replace(/\D/g, '').length === 13;
export const isValidTaxId = (taxId) => taxId.replace(/\D/g, '').length === 15;
export const isValidRut = (rut) => rut.replace(/\D/g, '').length === 12;
export const isValidNit = (nit) => nit.replace(/\D/g, '').length === 14;
export const isValidNif = (nif) => nif.replace(/\D/g, '').length === 9;

// Função para selecionar a máscara e validação de acordo com o tipo de documento
export const getDocumentMaskAndValidation = (documentType) => {
    switch (documentType) {
        case 'CNPJ': return { mask: applyCnpjMask, validate: isValidCnpj };
        case 'RUC': return { mask: applyRucMask, validate: isValidRuc };
        case 'Tax ID': return { mask: applyTaxIdMask, validate: isValidTaxId };
        case 'RUT': return { mask: applyRutMask, validate: isValidRut };
        case 'NIT': return { mask: applyNitMask, validate: isValidNit };
        case 'NIF': return { mask: applyNifMask, validate: isValidNif };
        default: return { mask: (value) => value, validate: () => true };
    }
};
