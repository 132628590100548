import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { applyCnpjMask, isValidCnpj } from '../../../../services/documentService';
import axios from 'axios';
import Ico from '../../assets/Lfather.png';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../../src/tailwind.css';

function CheckPJ({ onNext, setCompanyID, setCurrentStep, setCompanyIDExists, typeID }) {
  const { t } = useTranslation();
  const [companyID, setCompanyIDInput] = useState('');
  const [isCompanyIDValid, setIsCompanyIDValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  console.log('TypeID no CheckPJ:', typeID); // Verifique aqui

  const handleNextClick = async () => {
    if (!typeID) {
      setMessage('TypeID não está definido. Por favor, selecione um tipo de documento.');
      return;
    }

    const isValid = isValidCnpj(companyID);
    setIsCompanyIDValid(isValid);

    if (isValid) {
      setCompanyID(companyID);

      setLoading(true);

      try {
        const response = await axios.get(`https://api.father.srv.br/api/v2/companies/exists?companyID=${companyID}&typeID=${typeID}`);
        await new Promise(resolve => setTimeout(resolve, 1500)); // Simula um atraso
        setLoading(false);

        const exists = response.data.exists;
        setCompanyIDExists(exists);

        if (exists) {
          setMessage('CompanyID encontrado! Redirecionando para o passo 4.');
          setCurrentStep(4);
        } else {
          setMessage('CompanyID não encontrado! Redirecionando para o passo 2.');
          setCurrentStep(2);
        }
      } catch (error) {
        console.error('Erro ao verificar CompanyID:', error.message || error);
        setLoading(false);
        setMessage('Erro ao verificar CompanyID. Tente novamente mais tarde.');
      }
    } else {
      setMessage('CNPJ inválido. Por favor, insira um CNPJ válido.');
    }
  };

  const handleInputChange = (e) => {
    setCompanyIDInput(applyCnpjMask(e.target.value)); // Aplica a máscara de CNPJ e atualiza o estado local
  };

  return (
    <section className='center-comp'>
      <img src={Ico} className='Ico' alt='Icon' />
      <h4 className='icoText'>{t('register')}</h4>
      <div>
        <form className='formdataRegisterInpt'>
          <label className='lbInpt'>{t('cnpj')}</label>
          <input
            className={`bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert ${!isCompanyIDValid ? 'border-red-500' : ''}`}
            placeholder={t('enter_cnpj')}
            type='text'
            value={companyID}
            onChange={handleInputChange}
          />
          {!isCompanyIDValid && <p className="validInfos">{t('invalid_cnpj')}</p>}
          <div className='termInfo'><p>{t('terms_of_use')}</p></div>
        </form>
        <button className='action' onClick={handleNextClick}>
          <p>{t('next')}</p>
        </button>
        {loading && (
          <div className='loading-circle'></div>
        )}
      </div>
    </section>
  );
}

export default CheckPJ;
