import '../Layout/footer.css'

function FooterRounds() {
    return (
        <section id='footerBar'>
            <div id='TextFooter'><h6>Father Rounds</h6></div>
            <div id='TextFooter'><p>Technology developed by Father & Company®</p></div>
        </section>
    )
}

export default FooterRounds;