import { useState, useEffect } from "react"
import questions from "../../db/questions.json"
import Question from "./Question"

export default function Questions({ segment, onPrevStep, onNextStep, onQuestionsAnswered }) {
  const [questionNumber, setQuestionNumber] = useState(0)
  const [answers, setAnswers] = useState([])
  const [filteredQuestions, setFilteredQuestions] = useState([])

  useEffect(() => {
    setFilteredQuestions(questions.filter(question => question.segmt === segment))
  }, [segment])
  

  function onAltSelected(alt) {
    const updatedAnswers = [...answers, alt]
    setAnswers(updatedAnswers)
    if (questionNumber < filteredQuestions.length - 1) {
      setQuestionNumber(questionNumber + 1)
    } else {
      onQuestionsAnswered(updatedAnswers)
      onNextStep()
    }
  }

  function handlePrev() {
    setAnswers(a => a.splice(-1))
    if (questionNumber === 0) {
      onPrevStep()
    }

    setQuestionNumber(questionNumber - 1)
  }

  return (
    filteredQuestions[questionNumber]
    ? <Question question={filteredQuestions[questionNumber]} onPrev={handlePrev} onAltSelected={onAltSelected} />
    : null
  );
}

