import axios from 'axios';
import sessionService from './sessionService';

const API_URL = 'https://api.father.srv.br/api/v2/users/login'; // Ajuste a URL conforme necessário

const login = async (userData) => {
    try {
        if (!userData.email || !userData.senha) {
            return { success: false, message: 'Email e senha são obrigatórios' };
        }

        const response = await axios.post(API_URL, {
            email: userData.email,
            senha: userData.senha
        });

        // Verifique toda a resposta para encontrar a estrutura correta
        console.log('Resposta do login:', response.data);

        // Desestruturação dos dados importantes da resposta
        const { token, companyID, type } = response.data; // Alterado para companyID

        // Armazenar o email e o token no localStorage
        sessionService.login(userData.email, token);

        // Armazenar o companyID no localStorage se disponível
        if (companyID) {
            localStorage.setItem('userCompanyID', companyID); // Alterado para userCompanyID
        } else {
            console.warn('CompanyID não encontrado na resposta do login');
        }

        // Armazenar o tipo de usuário no localStorage se disponível
        if (type) {
            localStorage.setItem('userType', type);
        } else {
            console.warn('Tipo de usuário (type) não encontrado na resposta do login');
        }

        return { success: true, data: response.data };
    } catch (error) {
        // Log adicional para ajudar a depurar erros
        console.error('Erro ao fazer login:', error);

        const errorMessage = error.response?.data?.message || 'Falha no login. Verifique suas credenciais.';
        return { success: false, message: errorMessage };
    }
};

export default { login };
