import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'; 
import LanguageSelector from '../../Components/Translate/button';
import '../FormLogin/formLogin.css';
import Ico from '../../assets/Lfather.png';
import '../../../../tailwind.css';
import loginService from '../../../../services/loginService'; 

function FormLogin() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email || !senha) {
            setError('Por favor, insira seu email e senha.');
            return;
        }

        const userData = { email, senha };
        try {
            const result = await loginService.login(userData);

            if (result.success) {
                // Armazena o email no localStorage
                localStorage.setItem('userEmail', email);
                navigate('/rounds/home');
            } else {
                setError(result.message);
            }
        } catch (error) {
            setError('Erro ao tentar realizar login. Tente novamente mais tarde.');
            console.error('Erro no login:', error);
        }
    };

    return (
        <section className='center-comp'>
            <img src={Ico} className='Ico' alt='Icon' />
            <h4 className='icoText'>{t('login')}</h4>
            <div>
                <form className='formdataLogin' onSubmit={handleSubmit}>
                    <label className='lbInpt'>{t('email')}</label>
                    <input
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder={t('email')}
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label className='lbInpt'>{t('password')}</label>
                    <input
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder={t('enter_password')}
                        type='password'
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                        required
                    />
                    <button className='action' type='submit'><p>{t('login')}</p></button>
                </form>
                <div className='subForm'><p>{t('forgot_password')}</p></div>
                <div className='no-acc'>
                    <p>
                        {t('no_account')} 
                        <Link to="/rounds/register">
                            <strong>{t('register_now')}</strong>
                        </Link>
                    </p>
                </div>
            </div>
            {error && <p className="validInfos top2">{error}</p>}
        </section>
    );
}

export default FormLogin;
