import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'lucide-react';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../tailwind.css';
import '../Item/itens.css';

function SubSegmentSelect({ selectedSegment, resetSelection, onSubSegmentSelect }) {
    const { t } = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);

    // Acessa os subsegmentos usando `t` corretamente
    const subSegments = t('subSegments', { returnObjects: true }) || {};
    
    // Filtra os subsegmentos com base no segmento selecionado
    const filteredSubSegments = subSegments[selectedSegment] || [];

    // Reseta a seleção de subsegmentos quando o segmento mudar
    useEffect(() => {
        if (resetSelection) {
            setSelectedOptions([]);
        }
    }, [selectedSegment, resetSelection]);

    // Envia as opções selecionadas para o componente pai
    useEffect(() => {
        onSubSegmentSelect(selectedOptions);
    }, [selectedOptions, onSubSegmentSelect]);

    // Função para adicionar ou remover subsegmento da seleção
    const handleOptionSelect = (subSegment) => {
        setSelectedOptions((prevSelected) =>
            prevSelected.includes(subSegment)
                ? prevSelected.filter(option => option !== subSegment)
                : [...prevSelected, subSegment]
        );
    };

    // Função para formatar o texto do input
    const formatSelectedOptions = (options) => {
        const MAX_LENGTH = 30;
        const joinedOptions = options.join(', ');
        if (joinedOptions.length > MAX_LENGTH) {
            return `${joinedOptions.substring(0, MAX_LENGTH)}...`;
        }
        return joinedOptions;
    };

    return (
        <section id='selectBox' style={{ position: 'relative' }}>
            <section id='category-select'>
                <input
                    type='checkbox'
                    className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                    placeholder={t('select')}
                    id='options-view-button'
                    checked={isOptionsVisible}
                    onChange={() => setIsOptionsVisible(!isOptionsVisible)}
                />
                <div id='selectButton'>
                    <div
                        id='selectedValue'
                        style={{ color: 'black', fontWeight: 500 }}
                    >
                        {selectedOptions.length > 0 ? formatSelectedOptions(selectedOptions) : t('select')}
                    </div>
                    <div id='chevrons'>
                        <ChevronDown size={20} id='down' style={{ display: isOptionsVisible ? 'none' : 'block' }} />
                        <ChevronUp size={20} id='up' style={{ display: isOptionsVisible ? 'block' : 'none' }} />
                    </div>
                </div>
            </section>
            <ul
                id="options"
                style={{
                    display: isOptionsVisible ? 'block' : 'none',
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    zIndex: 10,
                    backgroundColor: '#F3F3F3',
                    width: '100%',
                    maxHeight: '150px',
                    overflowY: 'auto',
                }}
            >
                {filteredSubSegments.length > 0 ? (
                    filteredSubSegments.map((subSegment, index) => (
                        <li className="option" key={index} onClick={() => handleOptionSelect(subSegment)}>
                            <input
                                type='checkbox'
                                name='subSegment'
                                value={subSegment}
                                checked={selectedOptions.includes(subSegment)}
                                readOnly
                            />
                            <span className='label'>{subSegment}</span>
                        </li>
                    ))
                ) : (
                    <li className="option">
                        <span className='label'>No subsegments available</span>
                    </li>
                )}
            </ul>
        </section>
    );
}

export default SubSegmentSelect;
