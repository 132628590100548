import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../../tailwind.css';
import MenuRounds from '../../Components/Layout/menu';
import '../../Components/Layout/menu.css';
import '../newProduct/newProduct.css';
import { Upload } from 'lucide-react';
import imageCompression from 'browser-image-compression'; // Importa a biblioteca
import FooterRounds from '../../Components/Layout/footer';

function NewProduct() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { keyRound } = useParams(); // Obtém o keyRound da URL

    const [dragActive, setDragActive] = useState(false);
    const [files, setFiles] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [productName, setProductName] = useState('');
    const [productDesc, setProductDesc] = useState('');
    const [unitValue, setUnitValue] = useState('');
    const [minOrder, setMinOrder] = useState('');
    const [catalogLink, setCatalogLink] = useState('');
    const [priceTable, setPriceTable] = useState('');
    const companyID = localStorage.getItem('userCompanyID'); // Recupera o companyID do local storage

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);

        if (event.dataTransfer.files) {
            const droppedFiles = Array.from(event.dataTransfer.files);
            await handleFileSelect(droppedFiles);
        }
    };

    const handleFileChange = async (event) => {
        if (event.target.files) {
            const selectedFiles = Array.from(event.target.files);
            await handleFileSelect(selectedFiles);
        }
    };

    const handleFileSelect = async (selectedFiles) => {
        const newFiles = [];
        for (const file of selectedFiles) {
            if (file.size > 10000000) { // Limite de 10MB
                alert('O tamanho máximo do arquivo é 10MB.');
                continue;
            }
            
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 0.2, // Limite de tamanho reduzido para 0.2MB
                maxWidthOrHeight: 2000, // Limite de dimensão reduzido
            });
            newFiles.push(compressedFile);
        }

        if (files.length + newFiles.length > 5) {
            alert('Você pode enviar no máximo 5 arquivos.');
            return;
        }
        setFiles([...files, ...newFiles]);
    };

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    const handleMinOrderChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setMinOrder(value);
        }
    };

    const handleUnitValueChange = (event) => {
        let inputValue = event.target.value.replace(/[^\d,]/g, ''); // Remove tudo que não é número ou vírgula
        inputValue = inputValue.replace(',', '.'); // Substitui vírgula por ponto
    
        // Formata para 00.00
        const formattedValue = inputValue.replace(/(\d*)(\d{2})$/, '$1.$2');
        setUnitValue(formattedValue);
    };
   
    // Função para verificar o limite de produtos antes de permitir a criação
    const checkProductLimit = async () => {
        try {
            const response = await fetch(`https://api.father.srv.br/api/v2/products/count?companyID=${companyID}&roundKey=${keyRound}`);
            if (response.ok) {
                const data = await response.json();
                console.log("Número de produtos encontrados:", data.count); // Log para depuração
                return data.count >= 5; // Garante a comparação correta
            } else {
                console.error('Erro ao verificar o limite de produtos.', response.statusText);
                return true; // Retorna true para prevenir criação se houver algum erro
            }
        } catch (error) {
            console.error('Erro:', error);
            return true; // Retorna true para evitar criação de produto em erro
        }
    };
    
    // Função para lidar com a criação de um novo produto
    const handleCreateProduct = async () => {
        const limitReached = await checkProductLimit();
        if (limitReached) {
            alert('O limite máximo de produtos é 5.');
            return;
        }

        const formData = new FormData();
        // Adiciona campos ao FormData
        formData.append('productName', productName);
        formData.append('productDesc', productDesc);
        formData.append('unitValue', parseFloat(unitValue).toFixed(2)); // Garante duas casas decimais
        formData.append('minOrder', parseInt(minOrder, 10));
        formData.append('catalogLink', catalogLink);
        formData.append('priceTable', priceTable);
        formData.append('companyID', companyID); // Usa companyID no lugar de userTax
        formData.append('roundKey', keyRound); // Adiciona roundKey
        formData.append('cashType', selectedCurrency);
    
        // Adiciona cada arquivo ao FormData
        files.forEach((file) => {
            formData.append('photos', file);
        });
    
        try {
            const response = await fetch('https://api.father.srv.br/api/v2/products/create-product', {
                method: 'POST',
                body: formData,
            });
    
            if (response.ok) {
                const result = await response.json();
                alert('Produto criado com sucesso!');
                // Navega para a página de detalhes da rodada, se necessário
                navigate('/rounds/home');
            } else {
                const errorData = await response.json(); // Obtém a mensagem de erro
                alert(`Erro: ${errorData.message || 'Erro ao criar produto.'}`);
            }
        } catch (error) {
            console.error('Erro:', error);
            alert('Erro ao enviar dados. Verifique a conexão com o servidor.');
        }
    };

    return (
        <section>
        <section className='menuContent'>
            <MenuRounds />
            <div className='titleNewProduct'><h3>Adicione seu produto</h3></div>
            <div className='boxProduct'>
                <div className='fileLink'>
                    <div
                        className={`newProductToForm ${dragActive ? 'drag-active' : ''}`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <label htmlFor="file-upload" className='upload-label'>
                            <Upload className='upload-icon' size={47} />
                            <p>{t('Envie suas fotos aqui')}</p>
                        </label>
                        <input
                            className='sendFile'
                            type="file"
                            id="file-upload"
                            onChange={handleFileChange}
                            multiple
                        />
                        {files.length > 0 && (
                            <div className='file-list'>
                                {files.map((file, index) => (
                                    <p key={index}>{file.name}</p>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='linkTop lineLink'>
                        <div>
                            <label className='lbInpt'>Link do catálogo</label>
                            <input
                                placeholder='URL do catálogo'
                                className='medLink bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                                value={catalogLink}
                                onChange={(e) => setCatalogLink(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className='lbInpt'>Link da tabela de preço</label>
                            <input
                                placeholder='URL da Tabela de Preço'
                                className='medLink bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                                value={priceTable}
                                onChange={(e) => setPriceTable(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <form className='formProduct'>
                    <label className='lbInpt'>Nome do produto</label>
                    <input
                        placeholder='Digite o nome do produto'
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                    />
                    <label className='lbInpt'>Descrição</label>
                    <textarea
                        placeholder='Adicione uma descrição aqui...'
                        className='desc bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        value={productDesc}
                        onChange={(e) => setProductDesc(e.target.value)}
                    />
                    <div className='lineProduct'>
                        <div>
                            <label className='lbInpt'>Moeda</label>
                            <select
                                value={selectedCurrency}
                                onChange={handleCurrencyChange}
                                className='selectTypePrice bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                            >
                                <option value="USD">USD $</option>
                                <option value="BRL">BRL $</option>
                                <option value="EUR">EUR $</option>
                                <option value="CNY">CNY $</option>
                            </select>
                        </div>
                        <div>
                            <label className='lbInpt'>Valor unitário</label>
                            <input
                                placeholder={`${selectedCurrency} $`}
                                className='minPrice bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                                value={unitValue}
                                onChange={handleUnitValueChange}
                            />
                        </div>
                    </div>

                    <label className='lbInpt'>Pedido mínimo</label>
                    <input
                        placeholder='Digite o pedido mínimo'
                        value={minOrder}
                        onChange={handleMinOrderChange}
                        className='medPrice bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                    />

                    <button 
                        className='buttonTop action' 
                        type="button"
                        onClick={handleCreateProduct}
                    >
                        <p>Criar produto</p>
                    </button>
                </form>
            </div>
        </section>
        <FooterRounds/>
        </section>
    );
}

export default NewProduct;
