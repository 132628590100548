import logo from "../../assets/images/logo.png"
import "../../App.css"
import "./summary.css"
import { useState, useEffect } from "react"
import questions from "../../db/questions.json"
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler,
} from 'chart.js';
import axios from 'axios';




ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler
)

export default function Summary({ info }) {
  const [avgCom, setAvgCom] = useState(0);
  const [avgDir, setAvgDir] = useState(0);
  const [avgFin, setAvgFin] = useState(0);
  const [avgMkt, setAvgMkt] = useState(0);
  const [avgAdm, setAvgAdm] = useState(0);
  const [avgGeneral, setAvgGeneral] = useState(0);
  const [resultText, setResultText] = useState('');
  const [levelText, setLevelText] = useState('');
  const companyName = info.userInfo.company;
  const [isVisible, setIsVisible] = useState(true);
  const [admLabel, setAdmLabel] = useState([]);
  const [admData, setAdmData] = useState([]);
  const [comLabel, setComLabel] = useState([]);
  const [comData, setComData] = useState([]);
  const [dirLabel, setDirLabel] = useState([]);
  const [dirData, setDirData] = useState([]);
  const [finLabel, setFinLabel] = useState([]);
  const [finData, setFinData] = useState([]);
  const [mktLabel, setMktLabel] = useState([]);
  const [mktData, setMktData] = useState([]);
  const segment = info.segment;
  const localization = info.userInfo.loc;
  console.log(localization);

  //  Define o Hook Use state para armazenar o tamanho da tela
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });






  const [textCom, setTextCom] = useState('');
  const [textMkt, setTextMkt] = useState('');
  const [textDir, setTextDir] = useState('');
  const [textFin, setTextFin] = useState('');
  const [textAdm, setTextAdm] = useState('');



  useEffect(function () {
    console.log("useEffect foi chamado!")






    const api = axios.create({
      baseURL: 'https://api.father.srv.br/api/v1',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer S1N1cTJHWUNpejlXZ2tZak1xamo2dz09`,
      }
    });
    
    async function sendDataApi(data) {
      console.log("Dados recebidos na API:", data); // Verificar o que está sendo recebido
      try {
        const response = await api.post('/diagnostics', data); // Substitua '/some-endpoint' pelo endpoint correto
        return response.data;
      } catch (error) {
        console.error('Erro ao enviar dados:', error);
      }
    }
    
    


    async function calculateAndSendData() {
      console.log('info.answers:', info.answers);




      //

      //Calcula a média por departamento / classificação
      function calculateAverageByClassificationAndDepartment(answers, questions) {
        const classificationAndDepartmentData = {};
        answers.forEach(answer => {
          const question = questions.find(q => q.id === answer.questionId);
          if (question && question.classf && question.dpto) {
            const key = `${question.classf}-${question.dpto}`;
            if (!classificationAndDepartmentData[key]) {
              classificationAndDepartmentData[key] = { sum: 0, count: 0 };
            }
            classificationAndDepartmentData[key].sum += answer.selectedAlt.weight;
            classificationAndDepartmentData[key].count++;
          }
        });
        const averages = {};
        for (const key in classificationAndDepartmentData) {
          averages[key] = (classificationAndDepartmentData[key].sum / classificationAndDepartmentData[key].count).toFixed(2);
        }
        return averages;
      }





      function calculateAverages(info, questions) {
        if (!info || !info.answers) {
          console.error('Info or info.answers is undefined');
          return;
        }

        // Define os departamentos
        const departments = ["COM", "MKT", "ADM", "FIN", "DIR"]

        // Puxa a média para cada departamento
        const averages = departments.reduce((acc, department) => {
          // Filtra as respostas do usuário para o departamento
          const departmentAnswers = info.answers.filter(answer => {
            // Encontra a pergunta correspondente à resposta
            const question = questions.find(q => q.id === answer.questionId)
            // Retorna verdadeiro se o departamento da pergunta for o departamento atual
            return question && question.dpto === department
          })
          // Calcula a soma dos pesos das respostas
          const sum = departmentAnswers.reduce((total, answer) => {
            return total + answer.selectedAlt.weight
          }, 0)
          // Calcula a média
          const avg = (sum / departmentAnswers.length).toFixed(1)
          // Adiciona a média ao objeto de médias
          return { ...acc, [department.toLowerCase()]: avg }
        }, {})

        // Calcula a média geral
        const total = info.answers.reduce((acc, answer) => {
          return acc + answer.selectedAlt.weight
        }, 0)
        const avgGeneral = (total / info.answers.length).toFixed(2)
        setAvgCom(averages.com);
        setAvgDir(averages.dir);
        setAvgFin(averages.fin);
        setAvgMkt(averages.mkt);
        setAvgAdm(averages.adm);
        setAvgGeneral(avgGeneral);

        return { ...averages, general: avgGeneral };




      }

      // Armazena o resultado de calculateAverages em uma variável
      const result = calculateAverages(info, questions);
      const averagesFromFunction = calculateAverageByClassificationAndDepartment(info.answers, questions);

      console.log("Esse é o avgFin:" + result.fin);
      console.log("Esse é o avgDir:" + result.dir);
      console.log("Esse é o avgCom:" + result.com);
      console.log("Esse é o avgMkt:" + result.mkt);
      console.log("Esse é o avgAdm:" + result.adm);
      console.log("Esse é o avgGeneral:" + result.general);

      //
      const avgESTATUDIR = averagesFromFunction["EST-ATU-DIR"];
      const avgINVDIR = averagesFromFunction["INV-DIR"];
      const avgPSSDIR = averagesFromFunction["PSS-DIR"];
      const avgJURDIR = averagesFromFunction["JUR-DIR"];
      const avgNETWDIR = averagesFromFunction["NETW-DIR"];

      const avgESTATUCOM = averagesFromFunction["EST-ATU-COM"];
      const avgINVCOM = averagesFromFunction["INV-COM"];
      const avgPSSCOM = averagesFromFunction["PSS-COM"];
      const avgPDCOM = averagesFromFunction["PD-COM"];
      const avgMECCOM = averagesFromFunction["MEC-COM"];
      const avgNETWCOM = averagesFromFunction["NETW-COM"];
      const avgPCIOCOM = averagesFromFunction["PCIO-COM"];

      const avgESTATUFIN = averagesFromFunction["EST-ATU-FIN"];
      const avgPEXPTRIBFIN = averagesFromFunction["P-EXP-TRIB-FIN"];
      const avgMDPAGFIN = averagesFromFunction["MD-PAG-FIN"];
      const avgLOGFIN = averagesFromFunction["LOG-FIN"];
      const avgCOMFIN = averagesFromFunction["COM-FIN"];
      const avgNETWFIN = averagesFromFunction["NETW-FIN"];
      const avgBNFFIN = averagesFromFunction["BNF-FIN"];

      const avgESTATUMKT = averagesFromFunction["EST-ATU-MKT"];
      const avgPSSMKT = averagesFromFunction["PSS-MKT"];
      const avgMECMKT = averagesFromFunction["MEC-MKT"];
      const avgNETWMKT = averagesFromFunction["NETW-MKT"];
      const avgPCIOMKT = averagesFromFunction["PCIO-MKT"];

      const avgESTATUADM = averagesFromFunction["EST-ATU-ADM"];
      const avgINVADM = averagesFromFunction["INV-ADM"];
      const avgPSSADM = averagesFromFunction["PSS-ADM"];
      const avgPDADM = averagesFromFunction["PD-ADM"];
      const avgTECADM = averagesFromFunction["TEC-ADM"];
      const avgPRSSADM = averagesFromFunction["PRSS-ADM"];
      const avgNETWADM = averagesFromFunction["NETW-ADM"];
      const avgPCIOADM = averagesFromFunction["PCIO-ADM"];
      const avgLEGADM = averagesFromFunction["LEG-ADM"];




      let labelAdm;
      let dataAdm;
      let labelCom;
      let dataCom;
      let labelDir;
      let dataDir;
      let labelFin;
      let dataFin;
      let labelMkt;
      let dataMkt;
      if (segment == "product") {
        labelAdm = ['Produto', 'Tecnologia', 'Processo', 'Networking', 'Estratégia de atuação'];
        dataAdm = [avgPDADM, avgTECADM, avgPRSSADM, avgNETWADM, avgESTATUADM];
        labelCom = ['Produto', 'Mercado', 'Estratégia de atuação', 'Pessoas', 'Investimento'];
        dataCom = [avgPDCOM, avgMECCOM, avgESTATUCOM, avgPSSCOM, avgINVCOM];
        labelDir = ['Estratégia de atuação', 'Investimento', 'Pessoas', 'Jurídico', 'Networking'];
        dataDir = [avgESTATUDIR, avgINVDIR, avgPSSDIR, avgJURDIR, avgNETWDIR];
        labelFin = ['Preço de exportação e tributação', 'Modalidades de pagamento', 'Comercial', 'Logística', 'Benefícios Fiscais'];
        dataFin = [avgPEXPTRIBFIN, avgMDPAGFIN, avgCOMFIN, avgLOGFIN, avgBNFFIN];
        labelMkt = ['Mercado', 'Estratégia de atuação', 'Pessoas', 'Posicionamento'];
        dataMkt = [avgMECMKT, avgESTATUMKT, avgPSSMKT, avgPCIOMKT];
      } else if (segment == "service") {
        labelAdm = ['Produto', 'Tecnologia', 'Processo', 'Networking', 'Estratégia de atuação'];
        dataAdm = [avgPDADM, avgTECADM, avgPRSSADM, avgNETWADM, avgESTATUADM];
        labelCom = ['Produto', 'Mercado', 'Estratégia de atuação', 'Pessoas', 'Investimento'];
        dataCom = [avgPDCOM, avgMECCOM, avgESTATUCOM, avgPSSCOM, avgINVCOM];
        labelDir = ['Estratégia de atuação', 'Investimento', 'Pessoas', 'Jurídico', 'Networking'];
        dataDir = [avgESTATUDIR, avgINVDIR, avgPSSDIR, avgJURDIR, avgNETWDIR];
        labelFin = ['Preço de exportação e tributação', 'Modalidades de pagamento', 'Comercial', 'Logística', 'Benefícios Fiscais'];
        dataFin = [avgPEXPTRIBFIN, avgMDPAGFIN, avgCOMFIN, avgLOGFIN, avgBNFFIN];
        labelMkt = ['Mercado', 'Estratégia de atuação', 'Pessoas', 'Posicionamento'];
        dataMkt = [avgMECMKT, avgESTATUMKT, avgPSSMKT, avgPCIOMKT];
      }

      setAdmLabel(labelAdm);
      setAdmData(dataAdm);
      setComLabel(labelCom);
      setComData(dataCom);
      setDirLabel(labelDir);
      setDirData(dataDir);
      setFinLabel(labelFin);
      setFinData(dataFin);
      setMktLabel(labelMkt);
      setMktData(dataMkt);

      // Determina o texto do resultado com base na média geral
      let textResult;
      let textLevel;
      if (result.general <= 6.5) {
        textResult = "Empresas nesse nível geralmente têm pouca ou nenhuma experiência ou presença internacional. Para empresas que estão começando a explorar o mercado internacional, é essencial entender as bases legais e fiscais para operações de exportação. Recomendamos investir em consultoria especializada em legislação e tributação para exportação, juntamente com treinamentos práticos sobre classificação fiscal, certificação de origem e assim conseguir garantir o correto preço para negociações de exportação, obtendo uma base sólida. Esses passos iniciais são fundamentais para uma entrada segura no mercado global.";
        textLevel = "Muito baixo";
      }
      else if (result.general > 6.5 && result.general <= 7.5) {
        textResult = "Empresas neste nível podem ter alguma experiência, mas ainda enfrentam desafios significativos na internacionalização, muitas vezes por falta de conhecimento do mercado em que deseja atuar. Para empresas que já têm alguma experiência em exportação, porém ainda buscam explorar mais oportunidades, é importante realizar uma análise aprofundada de competitividade. Recomendamos também a elaboração de um mapa de oportunidades para identificar market share de mercados e também parceiros estratégicos. Essas estratégias podem impulsionar a participação internacional da empresa.";
        textLevel = "Baixo nível";
      }
      else if (result.general > 7.5 && result.general <= 9) {
        textResult = "Empresas neste nível estão bem posicionadas no mercado nacional e querem expandir suas operações no mercado externo, mas ainda podem aprimorar seus processos. Para empresas com exportações recorrentes, pode ser hora de analisar importações com regimes tributários que favoreçam ainda mais a competitividade no preço além de ser fundamental avançar em estratégias de valor agregado para maximizar a competitividade global. Recomendamos a adoção de uma consultoria na revisão da formação de preço e também garantir o registro de marca nos países que pretende atender, visando aprimorar a percepção de valor dos produtos ou serviços oferecidos. Além disso, é crucial investir em consultoria especializada em planejamento estratégico internacional, que inclua ações detalhadas de análise de concorrência e identificação de players externos relevantes. Essas análises contínuas de posicionamento permitem à empresa manter-se à frente da concorrência global e identificar oportunidades estratégicas para expansão e crescimento sustentável.";
        textLevel = "Bom nível";
      }
      else if (result.general > 9) {
        textResult = "Empresas neste nível têm suas operações internacionais bem consolidadas. Para empresas líderes no mercado nacional, a busca pela internacionalização com posicionamento no mercado externo é sinônimo de excelência é contínua. Recomendamos consultoria especializada em análise de mercado global e desenvolvimento de estratégias de expansão personalizadas para mercados distintos aos já atendidos e análise de market share dos mercados em que já estejam posicionadas. Além disso, é crucial investir em programas de capacitação para manter a excelência operacional e a inovação constante.";
        textLevel = "Nível internacional";
      }

      setResultText(textResult);
      setLevelText(textLevel);


      const checkSegmentAndScore = () => {
        let tempTextCom = '';
        let tempTextMkt = '';
        let tempTextDir = '';
        let tempTextFin = '';
        let tempTextAdm = '';
        if (segment === 'produto' || segment === 'product') {
          if (result.general >= 0 && result.general < 6) {
            tempTextCom = 'Sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar todas as pessoas envolvidas neste processo. Focar neste primeiro momento em capacitação e fazer um Estudo de Viabilidade para entender mercados, seriam excelentes escolhas.';
            tempTextMkt = 'Sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar todas as pessoas envolvidas neste processo. Uma venda depende de muitos fatores internos e o Marketing contribui significativamente para isto.';
            tempTextDir = 'O desejo de internacionalizar sua empresa já é um sinal de que a Diretoria pensa de forma distinta, pois ela está atenta aos mercados nacional e internacional e compreende que a diversificação de canais pode mitigar os riscos. Deve estar atenta a toda a sua equipe interna, pois um processo de internacionalização somente terá êxito se todos estiverem preparados e engajados.';
            tempTextFin = 'Sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar todas as pessoas envolvidas neste processo, uma equipe bem preparada mitigará os erros que podem ser evitados.';
            tempTextAdm = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.';
          } else if (result.general >= 6 && result.general < 8) {
            tempTextCom = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais. Focar neste primeiro momento em capacitação e fazer um Estudo de Viabilidade para entender mercados, seriam excelentes escolhas.';
            tempTextMkt = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização e sua empresa esteja parcialmente preparada, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa. Ações de Marketing bem feitas possibilitarão a sua empresa muito mais sucesso na operação.';
            tempTextDir = 'Embora a Diretoria já tenha um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais. Conhecer o mercado global através de um estudo de mercado, definir uma equipe para atendimento ao mercado externo e treinar as habilidades dessa equipe seriam os primeiros passos para elevar o nível Get Global da sua empresa.';
            tempTextFin = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.';
            tempTextAdm = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.';
          } else if (result.general >= 8 && result.general <= 10) {
            tempTextCom = 'Sua equipe está quase pronta e cada vez mais alinhada com os objetivos da Diretoria, mas ainda assim, focar em um Estudo de Viabilidade para entender mercados, continua sendo uma excelente escolha, já que cada mercado possui suas particularidades.';
            tempTextMkt = 'Sua equipe está quase pronta e cada vez mais alinhada com os objetivos da Diretoria, mas ainda assim, precisa estar atenta e acompanhando a eficácia de suas ações de Marketing e buscando sempre por inovação.';
            tempTextDir = 'Sua empresa está no caminho! Sugerimos elencar ao Planejamento do Mercado Nacional o Planejamento Estratégico Internacional como forma de visualizar as possibilidades que o mercado externo possui.';
            tempTextFin = 'Sua equipe está quase pronta para estar cada vez mais alinhada com demais departamentos e poder desta forma, fazer parte do processo de internacionalização da empresa, mas ainda assim, sugerimos um alinhamento com empresa, consultor ou entidade para mitigação dos possíveis erros.';
            tempTextAdm = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.';
          }
        } else if (segment === 'serviço' || segment === 'service') {
          if (result.general >= 0 && result.general < 6) {
            tempTextCom = 'Sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar todas as pessoas envolvidas neste processo. Focar neste primeiro momento em capacitação e fazer um Estudo de Viabilidade para entender mercados, seriam excelentes escolhas.';
            tempTextMkt = 'Sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar todas as pessoas envolvidas neste processo. Uma venda depende de muitos fatores internos e o Marketing contribui significativamente para isto.';
            tempTextDir = 'O desejo de internacionalizar sua empresa já é um sinal de que a Diretoria pensa de forma distinta, pois ela está atenta aos mercados nacional e internacional e compreende que a diversificação de canais pode mitigar os riscos. Deve estar atenta a toda a sua equipe interna, pois um processo de internacionalização somente terá êxito se todos estiverem preparados e engajados.';
            tempTextFin = 'Sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar todas as pessoas envolvidas neste processo, uma equipe bem preparada mitigará os erros que podem ser evitados.';
            tempTextAdm = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.';
          } else if (result.general >= 6 && result.general < 8) {
            tempTextCom = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais. Focar neste primeiro momento em capacitação e fazer um Estudo de Viabilidade para entender mercados, seriam excelentes escolhas.';
            tempTextMkt = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização e sua empresa esteja parcialmente preparada, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa. Ações de Marketing bem feitas possibilitarão a sua empresa muito mais sucesso na operação.';
            tempTextDir = 'Embora a Diretoria já tenha um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais. Conhecer o mercado global através de um estudo de mercado, definir uma equipe para atendimento ao mercado externo e treinar as habilidades dessa equipe seriam os primeiros passos para elevar o nível Get Global da sua empresa.';
            tempTextFin = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.';
            tempTextAdm = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.';
          } else if (result.general >= 8 && result.general <= 10) {
            tempTextCom = 'Sua equipe está quase pronta e cada vez mais alinhada com os objetivos da Diretoria, mas ainda assim, focar em um Estudo de Viabilidade para entender mercados, continua sendo uma excelente escolha, já que cada mercado possui suas particularidades.';
            tempTextMkt = 'Sua equipe está quase pronta e cada vez mais alinhada com os objetivos da Diretoria, mas ainda assim, precisa estar atenta e acompanhando a eficácia de suas ações de Marketing e buscando sempre por inovação.';
            tempTextDir = 'Sua empresa está no caminho! Sugerimos elencar ao Planejamento do Mercado Nacional o Planejamento Estratégico Internacional como forma de visualizar as possibilidades que o mercado externo possui.';
            tempTextFin = 'Sua equipe está quase pronta para estar cada vez mais alinhada com demais departamentos e poder desta forma, fazer parte do processo de internacionalização da empresa, mas ainda assim, sugerimos um alinhamento com empresa, consultor ou entidade para mitigação dos possíveis erros.';
            tempTextAdm = 'Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.';
          }
        }
        setTextCom(tempTextCom);
        setTextMkt(tempTextMkt);
        setTextDir(tempTextDir);
        setTextFin(tempTextFin);
        setTextAdm(tempTextAdm);
      };


      checkSegmentAndScore();

      console.log("Dados a serem enviados:", {
        infoDiagnostic: {
          email: info.userInfo.email,
          company: companyName,
          name: info.userInfo.name,
          cnpj: info.userInfo.cnpj,
          localization: info.userInfo.loc,
          segment: segment,
          avgGeneral: result.general,
          avgCom: result.com,
          avgDir: result.dir,
          avgFin: result.fin,
          avgMkt: result.mkt,
          avgAdm: result.adm,
          chartAdm: dataAdm,
          chartCom: dataCom,
          chartDir: dataDir,
          chartFin: dataFin,
          chartMkt: dataMkt,
          data_envio: new Date().toISOString()
        }
      });
      

      await sendDataApi({
        infoDiagnostic: {
          email: info.userInfo.email,
          company: companyName,
          name: info.userInfo.name,
          cnpj: info.userInfo.cnpj,
          localization: info.userInfo.loc,
          segment: segment,
          avgGeneral: result.general,
          avgCom: result.com,
          avgDir: result.dir,
          avgFin: result.fin,
          avgMkt: result.mkt,
          avgAdm: result.adm,
          chartAdm: dataAdm,
          chartCom: dataCom,
          chartDir: dataDir,
          chartFin: dataFin,
          chartMkt: dataMkt,
          data_envio: new Date().toISOString()
        }
      });

    }
    calculateAndSendData();

  }, [])



  const capturaConteudoParaPDF = () => document.getElementById('content-id');

  const personalizacao = {
    filename: companyName + ' getGlobal®.pdf',
    method: 'save', // Abre o PDF no navegador ao invés de baixar automaticamente
    resolution: Resolution.MEDIUM,
    scale: 1, // Define a resolução alta para melhor qualidade de imagem
    page: {
      margin: Margin.NONE,
      x: 0,
      y: 0, // Define margens pequenas (em milímetros)
      format: [window.innerWidth * 0.1645833333, window.innerHeight * 0.2645833333 * 2.1],
      orientation: 'portrait', // Define a orientação do PDF como retrato (também pode ser 'landscape')
    },
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true
      }
    }
  };



  const handleClick = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 3000);
    setTimeout(() => {
      generatePDF(capturaConteudoParaPDF, personalizacao);
    }, 2000);

    // Alterar o background e o border-radius das divs com className 'logo' e 'bem-vindo'
    const logoDiv = document.querySelector('.logo');
    const bemVindoDiv = document.querySelector('.bem-vindo');
    if (logoDiv) {
      logoDiv.style.background = 'black';
      logoDiv.style.borderRadius = '20px';
    }
    if (bemVindoDiv) {
      bemVindoDiv.style.background = 'black';
      bemVindoDiv.style.borderRadius = '20px';
    }
  };


  return (
    <section className="sum-all">
    <section className="box-center-resultado" id="content-id">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>

      <div className="title-resultado">
        <div className="bem-vindo"><h1>Confira o resultado do diagnóstico <strong id="get">getGlobal®</strong> da sua empresa</h1></div>
      </div>
      <div class="line-1" id="line-1" >

        <div className="box-resultado-geral-p1">
          <div className="infos" >
            <div className="empresa"><strong id="item">EMPRESA</strong><h1 id="addEmpresa">{companyName}</h1></div>
          </div>
          <div className="table-nota"><div class="nota">
            <strong id="item">SCORE GLOBAL</strong><h1 id="nota">{avgGeneral}</h1></div>
            <div className="level"><strong id="item">LEVEL</strong><h1 id="level">{levelText}</h1></div></div>

          <div className="dptos">
            <div className="title-dptos"><h1>Área</h1><h1>%</h1></div>
            <div className="adm"><strong id="item">ADMINISTRATIVO</strong>
              <div className="pts"><h1 id="admpts">{avgAdm * 10}</h1></div>
            </div>

            <div className="com"><strong id="item">COMERCIAL</strong>
              <div className="pts"><h1 id="compts">{avgCom * 10}</h1></div>
            </div>

            <div className="dir"><strong id="item">DIRETORIA</strong>
              <div className="pts"><h1 id="dirpts">{avgDir * 10}</h1></div>
            </div>

            <div className="finan"><strong id="item">FINANCEIRO</strong>
              <div className="pts"><h1 id="finpts">{avgFin * 10}</h1></div>
            </div>

            <div className="mkt"><strong id="item">MARKETING</strong>
              <div className="pts"><h1 id="mktpts">{avgMkt * 10}</h1></div>
            </div>

          </div>
        </div>
        <div class="box-resultado-geral-p2">
          <div className="content-chart">
            <div className="title-chart"> <h1>Departamentos</h1>
              <div className="sub-chart"> <p>Média geral por departamento</p></div>
            </div>
            <div className="chart-box">

              <div className="bar">
                <label>ADMINISTRATIVO</label>
                <div className="progress"><div className="bar-adm" style={{ width: `${avgAdm * 10}%` }}><p>{avgAdm * 10}%</p></div></div>
              </div>

              <div className="bar">
                <label>COMERCIAL</label>
                <div className="progress"><div className="bar-com" style={{ width: `${avgCom * 10}%` }}><p>{avgCom * 10}%</p></div></div>
              </div>

              <div className="bar">
                <label>FINANCEIRO</label>
                <div className="progress"><div className="bar-fin" style={{ width: `${avgFin * 10}%` }}><p>{avgFin * 10}%</p></div></div>
              </div>

              <div className="bar">
                <label>DIRETORIA</label>
                <div className="progress"><div className="bar-dir" style={{ width: `${avgDir * 10}%` }}><p>{avgDir * 10}%</p></div></div>
              </div>

              <div className="bar">
                <label>MARKETING</label>
                <div className="progress" ><div className="bar-mkt" style={{ width: `${avgMkt * 10}%` }}><p>{avgMkt * 10}%</p></div></div>
              </div>

            </div>
          </div>
        </div>

      </div>


      <div class="line-2" id="line-2">
        <div class="box-resultado-geral-p3">
          <div class="content-resultado-2">
            <div class="nota-l2"><strong id="item">SUA NOTA É:</strong><h1 id="nota-l2">{avgGeneral}</h1></div>
            <div class="text-resultado"><h1 id="text-resultado">{resultText}</h1></div>
          </div>
        </div>
      </div>

      <div className="line-text">
        <div className="box-resultado-text">
          <p>Para uma empresa que busca expandir suas operações para o mercado internacional, a participação de cada departamento é fundamental para garantir o sucesso e a eficiência das operações de exportação ou importação. Neste diagnóstico trazemos os principais departamentos de uma empresa, mas de forma personalizada podemos atender as particularidades da sua empresa. Faça uma consulta com nossos especialistas. </p>
        </div>
      </div>

      <div class="line-3" id="line-3">
        <div class="box-resultado-geral-p4">
          <div class="content-dpto">
            <h1>Administrativo</h1>
            <p>Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.</p>
          </div>
          <div className="chart-js">
            <div className="content-chart">

              <div className="sub-chart"> <p>Classificações administrativas</p></div>

              <div className="chart-box">

                <div className="bar">
                  <label>PRODUTO</label>
                  <div className="progress"><div className="bar-adm-pd" style={{ width: `${admData[0] * 10}%` }}><p>{admData[0] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>TECNOLOGIA</label>
                  <div className="progress"><div className="bar-adm-tec" style={{ width: `${admData[1] * 10}%` }}><p>{admData[1] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>PROCESSO</label>
                  <div className="progress"><div className="bar-adm-prs" style={{ width: `${admData[2] * 10}%` }}><p>{admData[2] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>NETWORKING</label>
                  <div className="progress"><div className="bar-adm-net" style={{ width: `${admData[3] * 10}%` }}><p>{admData[3] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>ESTRATÉGIA DE ATUAÇÃO</label>
                  <div className="progress" ><div className="bar-adm-est" style={{ width: `${admData[4] * 10}%` }}><p>{admData[4] * 10}%</p></div></div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="line-4" id="line-4">
        <div class="box-resultado-geral-p4">
          <div class="content-dpto">
            <h1>Comercial</h1>
            <p>{textCom}</p>
          </div>

          <div className="chart-js">
            <div className="content-chart">

              <div className="sub-chart"> <p>Classificações comerciais</p></div>

              <div className="chart-box">

                <div className="bar">
                  <label>PRODUTO</label>
                  <div className="progress"><div className="bar-com-pd" style={{ width: `${comData[0] * 10}%` }}><p>{comData[0] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>MERCADO</label>
                  <div className="progress"><div className="bar-com-mec" style={{ width: `${comData[1] * 10}%` }}><p>{comData[1] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>ESTRATÉGIA DE ATUAÇÃO</label>
                  <div className="progress"><div className="bar-com-est" style={{ width: `${comData[2] * 10}%` }}><p>{comData[2] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>PESSOAS</label>
                  <div className="progress"><div className="bar-com-pss" style={{ width: `${comData[3] * 10}%` }}><p>{comData[3] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>INVESTIMENTO</label>
                  <div className="progress" ><div className="bar-com-inv" style={{ width: `${comData[4] * 10}%` }}><p>{comData[4] * 10}%</p></div></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="line-5" id="line-5">
        <div class="box-resultado-geral-p4">
          <div class="content-dpto">
            <h1>Diretoria</h1>
            <p>Embora a Diretoria já tenha um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais. Conhecer o mercado global através de um estudo de mercado, definir uma equipe para atendimento ao mercado externo e treinar as habilidades dessa equipe seriam os primeiros passos para elevar o nível Get Global da sua empresa.</p>
          </div>

          <div className="chart-js2">
            <div className="content-chart">

              <div className="sub-chart"> <p>Classificações da diretoria</p></div>

              <div className="chart-box">

                <div className="bar">
                  <label>ESTRATÉGIA DE ATUAÇÃO</label>
                  <div className="progress"><div className="bar-dir-est" style={{ width: `${dirData[0] * 10}%` }}><p>{dirData[0] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>INVESTIMENTO</label>
                  <div className="progress"><div className="bar-dir-inv" style={{ width: `${dirData[1] * 10}%` }}><p>{dirData[1] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>PESSOAS</label>
                  <div className="progress"><div className="bar-dir-pss" style={{ width: `${dirData[2] * 10}%` }}><p>{dirData[2] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>JURÍDICO</label>
                  <div className="progress"><div className="bar-dir-jur" style={{ width: `${dirData[3] * 10}%` }}><p>{dirData[3] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>NETWORKING</label>
                  <div className="progress" ><div className="bar-dir-net" style={{ width: `${dirData[4] * 10}%` }}><p>{dirData[4] * 10}%</p></div></div>
                </div>

              </div>
            </div>
          </div></div>
      </div>

      <div class="line-6" id="line-6">
        <div class="box-resultado-geral-p4">
          <div class="content-dpto">
            <h1>Financeiro</h1>
            <p>Embora as pessoas deste depto tenham um conhecimento sobre todas as etapas de um processo de internacionalização, sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar na definição dos objetivos da empresa neste caminho e poderem iniciar as negociações internacionais.</p>
          </div>
          <div className="chart-js3">
            <div className="content-chart">

              <div className="sub-chart"> <p>Classificações da financeiras</p></div>

              <div className="chart-box">

                <div className="bar">
                  <label>PREÇO DE EXPORTAÇÃO E TRIBUTOS</label>
                  <div className="progress"><div className="bar-fin-prexp" style={{ width: `${finData[0] * 10}%` }}><p>{finData[0] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>MODALIDADES DE PAGAMENTO</label>
                  <div className="progress"><div className="bar-fin-mod" style={{ width: `${finData[1] * 10}%` }}><p>{finData[1] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>COMERCIAL</label>
                  <div className="progress"><div className="bar-fin-com" style={{ width: `${finData[2] * 10}%` }}><p>{finData[2] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>LOGÍSTICA</label>
                  <div className="progress"><div className="bar-fin-log" style={{ width: `${finData[3] * 10}%` }}><p>{finData[3] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>BENEFÍCIOS FISCAIS</label>
                  <div className="progress" ><div className="bar-fin-ben" style={{ width: `${finData[4] * 10}%` }}><p>{finData[4] * 10}%</p></div></div>
                </div>

              </div>
            </div>
          </div></div>
      </div>

      <div class="line-7" id="line-7">
        <div class="box-resultado-geral-p4">
          <div class="content-dpto">
            <h1>Marketing</h1>
            <p>Sugerimos a busca por uma empresa, consultor ou entidade que possam apoiar todas as pessoas envolvidas neste processo. Uma venda depende de muitos fatores internos e o Marketing contribui significativamente para isto.</p>
          </div>
          <div className="chart-js3">
            <div className="content-chart">

              <div className="sub-chart"> <p>Classificações de marketing</p></div>

              <div className="chart-box">

                <div className="bar">
                  <label>MERCADO</label>
                  <div className="progress"><div className="bar-mkt-mec" style={{ width: `${mktData[0] * 10}%` }}><p>{mktData[0] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>ESTRATÉGIA DE ATUAÇÃO</label>
                  <div className="progress"><div className="bar-mkt-est" style={{ width: `${mktData[1] * 10}%` }}><p>{mktData[1] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>PESSOAS</label>
                  <div className="progress"><div className="bar-mkt-pss" style={{ width: `${mktData[2] * 10}%` }}><p>{mktData[2] * 10}%</p></div></div>
                </div>

                <div className="bar">
                  <label>POSICIONAMENTO</label>
                  <div className="progress"><div className="bar-mkt-pssc" style={{ width: `${mktData[3] * 10}%` }}><p>{mktData[3] * 10}%</p></div></div>
                </div>


              </div>
            </div>
          </div></div>
      </div>

      <div class="frase-final">
        <p>Se você não encontrar clientes internacionais antes dos seus concorrentes globais, eles encontraram seu cliente nacional. A tecnologia acelerou esponencialmente as negociações mundiais e as empresas que não estiverem atentas a este momento tendem a despararecer.<strong> "Sheyla Pereria e Thiago Raitez, Father Estratégias Internacionais"</strong></p>

      </div>



      <div class="footer"></div>


      <div>
        {/* Restante do seu código */}
        {isVisible ? (
          <div class="box-footer">
            <div class="box-down">
              <button id="down" onClick={handleClick}>
                <h1>Baixar diagnóstico</h1>
              </button>
            </div>
          </div>
        ) : null}
      </div>


    </section>
</section>
  )
}

