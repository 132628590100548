import { useState , useEffect } from "react";
import logo from "../../assets/images/logo.png";
import "./user-info.css";
import InputMask from 'react-input-mask';

export default function UserInfo({ onNextStep, onSubmit }) {
  const [selectBorderClass, setSelectBorderClass] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [isCnpjValid, setIsCnpjValid] = useState(true);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const [formData, setFormData] = useState({
    company: "",
    email: "",
    name: "",
    cnpj: "",
    loc: "",
    acceptedTerms: false, // Campo para o "Check marker"
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (name === "cnpj") {
      setIsCnpjValid(validateCNPJ(value));
    }
    if (!value) {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: "" }));
    }
  };

  const isFieldEmpty = (fieldName) => {
    return !formData[fieldName];
  };

  const validateCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj.length !== 14) return false;

    // Elimina CNPJs inválidos conhecidos
    if (/^(\d)\1+$/.test(cnpj)) return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setAttemptedSubmit(true);

    // Verifique cada campo individualmente
    const emptyFields = ["company", "email", "name", "cnpj", "loc"];
    let hasEmptyField = false;

    emptyFields.forEach((field) => {
      if (!formData[field]) {
        hasEmptyField = true;
      }
    });

    if (hasEmptyField) {
      alert("Por favor, preencha todos os campos.");
      return;
    }

    // Valide o CNPJ
    if (!validateCNPJ(formData.cnpj)) {
      setIsCnpjValid(false);
      alert("CNPJ inválido. Por favor, insira um CNPJ válido.");
      return;
    }

    // Verifique se o usuário aceitou os termos
    if (!formData.acceptedTerms) {
      alert("Você precisa aceitar os termos para continuar.");
      return;
    }

    onSubmit(formData);
    onNextStep();
  };

  const getBorderClass = (fieldName) => {
    if (fieldName === "cnpj") {
      return (attemptedSubmit && (!isCnpjValid || isFieldEmpty(fieldName))) ? "red-border" : "";
    }
    return attemptedSubmit && isFieldEmpty(fieldName) ? "red-border" : "";
  };

  const handlePrivacyPolicyClick = (event) => {
    event.preventDefault();
    setShowPrivacyPolicy(true);
  };

  const closePrivacyPolicy = () => {
    setShowPrivacyPolicy(false);
  };

  useEffect(() => {
    // Define a cor de fundo quando o componente é montado
    document.body.style.backgroundColor = '#000000';

    // Reverte a cor de fundo quando o componente é desmontado
    return () => {
      document.body.style.backgroundColor = '';
    };
  }); // O array vazio [] assegura que o efeito seja executado apenas uma vez

  return (
    <section className="all-bg">
    <div className="center-entrar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="entrar">
        <h2>Insira seus dados para começar</h2>
        <form className="inputs" onSubmit={handleSubmit} id="diag-getGlobal">
          <div className="input-info">
            <label htmlFor="company">NOME DA EMPRESA</label>
            <input
              className={`insert-info ${getBorderClass("company")}`}
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Digite o nome fantasia"
            />
          </div>

          <div className="input-info">
            <label htmlFor="cnpj">CNPJ</label>
            <InputMask
              mask="99.999.999/9999-99"
              className={`insert-info ${getBorderClass("cnpj")}`}
              type="text"
              id="cnpj"
              name="cnpj"
              value={formData.cnpj}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="XX.XXX.XXX/YYYY-ZZ"
            />
          </div>

          <div className="input-info">
            <label htmlFor="name">NOME</label>
            <input
              className={`insert-info ${getBorderClass("name")}`}
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Digite seu nome"
            />
          </div>

          <div className="input-info">
            <label htmlFor="email">E-MAIL</label>
            <input
              className={`insert-info ${getBorderClass("email")}`}
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Digite seu @ email"
            />
          </div>

          <div className="input-info">
            <label htmlFor="loc">DE ONDE VOCÊ VEM?</label>
            <select
              name="loc"
              id="loc"
              className={`inpdatalist ${getBorderClass("loc")}`}
              value={formData.loc}
              onChange={handleChange}
            >
              <option value="">Selecione uma opção</option>
              <option value="Consultoria">Consultoria</option>
              <option value="Hub das américas">Hub das américas</option>
              <option value="Anúncio na internet">Anúncio na internet</option>
              <option value="Imprensa">Imprensa</option>
              <option value="GTM SEBRAE">GTM SEBRAE</option>
            </select>
          </div>

          <div className="input-info">
            <label htmlFor="acceptedTerms" className="accTerm">
              <input
                type="checkbox"
                id="acceptedTerms"
                name="acceptedTerms"
                checked={formData.acceptedTerms}
                onChange={handleChange}
              />
              <p>
                Li e concordo os termos de uso da <a href="#" onClick={handlePrivacyPolicyClick}>Política de Privacidade</a>
              </p>
            </label>
          </div>

          <input className="start-diagnostic" type="submit" value="Começar diagnóstico" />
        </form>
      </div>
      
      {showPrivacyPolicy && (
        <div className="overlay">
           <button className="close-overlay" onClick={closePrivacyPolicy}>X</button>
          <div className="overlay-content">
           
            <h2><span style={{ color: "rgb(68, 68, 68)" }}>Política Privacidade</span></h2>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>A sua privacidade é importante para nós. É política do Diagnóstico getGlobal respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site <a href="http://app.father.srv.br">Diagnóstico getGlobal</a>, e outros sites que possuímos e operamos.</span></p>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.</span></p>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</span></p>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</span></p>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas&nbsp;</span><a href="https://politicaprivacidade.com/" rel="noopener noreferrer" target="_blank" style={{ backgroundColor: "transparent", color: "rgb(68, 68, 68)" }}>políticas de privacidade</a><span style={{ color: "rgb(68, 68, 68)" }}>.</span></p>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</span></p>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.</span></p>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>
              <ul>
                <li><span style={{ color: "rgb(68, 68, 68)" }}>O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você.</span></li>
                <li><span style={{ color: "rgb(68, 68, 68)" }}>Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.</span></li>
                <li><span style={{ color: "rgb(68, 68, 68)" }}>Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados ​​por este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse.</span></li>
                <li><span style={{ color: "rgb(68, 68, 68)" }}>Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma compra.</span></li>
              </ul>
              <p><br /></p>
            </span></p>
            <h3><span style={{ color: "rgb(68, 68, 68)" }}>Compromisso do Usuário</span></h3>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Diagnóstico getGlobal oferece no site e com caráter enunciativo, mas não limitativo:</span></p>
            <ul>
              <li><span style={{ color: "rgb(68, 68, 68)" }}>A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</span></li>
              <li><span style={{ color: "rgb(68, 68, 68)" }}>B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, </span><span style={{ color: "rgb(33, 37, 41)" }}><a href='https://betnacionalbrasil.br.com' style={{ color: "inherit", textDecoration: "none", fontSize: "inherit" }}>Bet Nacional</a></span><span style={{ color: "rgb(68, 68, 68)" }}> ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</span></li>
              <li><span style={{ color: "rgb(68, 68, 68)" }}>C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Diagnóstico getGlobal, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</span></li>
            </ul>
            <h3><span style={{ color: "rgb(68, 68, 68)" }}>Mais informações</span></h3>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.</span></p>
            <p><span style={{ color: "rgb(68, 68, 68)" }}>Esta política é efetiva a partir de 3 June 2024 17:25</span></p>
          </div>
        </div>
      )}
    </div></section>
  );
}
