import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'lucide-react';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../tailwind.css';
import '../Item/itens.css';

const countries = [
    'Brazil', 'United States', 'Canada', 'Germany', 'France', 'United Kingdom',
    'Argentina', 'Japan', 'Australia', 'China', 'India', 'South Africa', 'Mexico',
    'Russia', 'Italy', 'Spain', 'Portugal', 'Netherlands', 'Switzerland', 'Sweden',
    'Norway', 'Finland', 'Denmark', 'Ireland', 'New Zealand', 'South Korea',
    'Turkey', 'Indonesia', 'Saudi Arabia', 'United Arab Emirates', 'Nigeria',
    'Egypt', 'Kenya', 'Chile', 'Colombia', 'Peru', 'Venezuela'
];

function CountrySelect({ onCountryChange }) {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(t('select'));
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#C5C5C5');
    const [fontWeight, setFontWeight] = useState(400);

    const handleOptionSelect = (label) => {
        setSelectedOption(label);
        setIsOptionsVisible(false);
        setSelectedColor('black');
        setFontWeight(500);
        if (onCountryChange && typeof onCountryChange === 'function') {
            onCountryChange(label); // Passa o valor selecionado para a função do props
        }
    };

    return (
        <section id='selectBox' style={{ position: 'relative' }}>
            <section id='category-select'>
                <input
                    type='checkbox'
                    className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                    placeholder={t('select')}
                    id='options-view-button'
                    checked={isOptionsVisible}
                    onChange={() => setIsOptionsVisible(!isOptionsVisible)}
                />
                <div id='selectButton'>
                    <div
                        id='selectedValue'
                        style={{ color: selectedColor, fontWeight: fontWeight }}
                    >
                        {selectedOption}
                    </div>
                    <div id='chevrons'>
                        <ChevronDown size={20} id='down' style={{ display: isOptionsVisible ? 'none' : 'block' }} />
                        <ChevronUp size={20} id='up' style={{ display: isOptionsVisible ? 'block' : 'none' }} />
                    </div>
                </div>
            </section>
            <ul
                id="options"
                style={{
                    display: isOptionsVisible ? 'block' : 'none',
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    zIndex: 10,
                    backgroundColor: '#F3F3F3',
                    width: '100%',
                    maxHeight: '200px',
                    overflowY: 'scroll'
                }}
            >
                {countries.map((country) => (
                    <li key={country} className="option" onClick={() => handleOptionSelect(country)}>
                        <input type='radio' name='country' value={country} />
                        <span className='label'>{country}</span>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default CountrySelect;
