import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function ProductsGrid({ roundKey, companyLocation }) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    // Use the 'roundKey' directly instead of accessing location state
    const handleNewProductClick = () => {
        if (roundKey) {
            navigate(`/rounds/round/${roundKey}/newproduct`);
        } else {
            setError('Dados do round não encontrados.');
        }
    };

    const handleDeleteProduct = async (productId) => {
        if (!roundKey) {
            setError('Dados do round não encontrados.');
            return;
        }

        try {
            await axios.delete(`https://api.father.srv.br/api/v2/products/delete-product/${productId}`);
            setProducts(products.filter(product => product._id !== productId));
        } catch (err) {
            setError(`Erro ao excluir produto: ${err.message}`);
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            const companyID = localStorage.getItem('userCompanyID');
            try {
                const response = await axios.get('https://api.father.srv.br/api/v2/products/filtered-products', {
                    params: { roundKey: roundKey, companyID: companyID }
                });
                setProducts(response.data);
            } catch (err) {
                setError('Erro ao carregar os produtos: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        if (roundKey) fetchProducts();
    }, [roundKey]);

    if (loading) return <p>Carregando produtos...</p>;
    if (error) return <p>{error}</p>;

    return (
        <section>
            <div className='newProduct' onClick={handleNewProductClick}>
                <svg width="50" height="50" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M36.4229 60.6513H44.4995V44.4981H60.6528V36.4215H44.4995V20.2682H36.4229V36.4215H20.2697V44.4981H36.4229V60.6513ZM40.4612 80.8429C34.8749 80.8429 29.6251 79.7828 24.7118 77.6627C19.7985 75.5426 15.5247 72.6653 11.8902 69.0308C8.2557 65.3963 5.37841 61.1224 3.25829 56.2092C1.13818 51.2959 0.078125 46.0461 0.078125 40.4598C0.078125 34.8734 1.13818 29.6236 3.25829 24.7104C5.37841 19.7971 8.2557 15.5232 11.8902 11.8887C15.5247 8.25424 19.7985 5.37694 24.7118 3.25683C29.6251 1.13672 34.8749 0.0766602 40.4612 0.0766602C46.0476 0.0766602 51.2974 1.13672 56.2106 3.25683C61.1239 5.37694 65.3978 8.25424 69.0323 11.8887C72.6668 15.5232 75.544 19.7971 77.6642 24.7104C79.7843 29.6236 80.8443 34.8734 80.8443 40.4598C80.8443 46.0461 79.7843 51.2959 77.6642 56.2092C75.544 61.1224 72.6668 65.3963 69.0323 69.0308C65.3978 72.6653 61.1239 75.5426 56.2106 77.6627C51.2974 79.7828 46.0476 80.8429 40.4612 80.8429ZM40.4612 72.7662C49.4801 72.7662 57.1193 69.6366 63.3786 63.3772C69.638 57.1178 72.7677 49.4787 72.7677 40.4598C72.7677 31.4409 69.638 23.8017 63.3786 17.5424C57.1193 11.283 49.4801 8.15328 40.4612 8.15328C31.4423 8.15328 23.8032 11.283 17.5438 17.5424C11.2844 23.8017 8.15475 31.4409 8.15475 40.4598C8.15475 49.4787 11.2844 57.1178 17.5438 63.3772C23.8032 69.6366 31.4423 72.7662 40.4612 72.7662Z" fill="#0C8CE9" />
                </svg>
                <p>Adicionar novo produto</p>
            </div>
            <div className='gridProducts'>
                {products.length > 0 ? (
                    products.map(product => (
                        <div key={product._id} className='products'>
                            <div className='productData'>
                                <h4>{product.productName}</h4>
                                <div className='line'></div>
                                <label className='lbProduct'>Preço</label>
                                <h5>{product.unitValue} {product.cashType}</h5>
                                <label className='lbProduct'>Pedido mínimo</label>
                                <h5>{product.minOrder}</h5>
                                <label className='lbProduct'>Origem</label>
                                <h5>{companyLocation.state}/{companyLocation.country}</h5>
                            </div>
                            <div className='img'>
                                <img src={product.photoUrls[0]} alt={product.productName} />
                            </div>
                            <div className='trash' onClick={() => handleDeleteProduct(product._id)}>
                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 6H5H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Nenhum produto encontrado.</p>
                )}
            </div>
        </section>
    );
}

export default ProductsGrid;
