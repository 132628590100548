import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../tailwind.css';
import Ico from '../../assets/Lfather.png';
import SegmentSelect from '../Item/segment';
import StateSelect from '../Item/state';
import CitySelect from '../Item/city';
import SubSegmentSelect from '../Item/subsegment';
import CountrySelect from './countrySelect';

function CompanyComplet({ companyData = {}, setCompanyData, onNext, onSegmentSelect, onSubSegmentSelect, typeID }) {
    const { t } = useTranslation();
    const [selectedState, setSelectedState] = useState(companyData.state || '');
    const [selectedSegment, setSelectedSegment] = useState(companyData.segment || '');
    const [selectedSubsegment, setSelectedSubsegment] = useState(companyData.subsegment || '');
    const [selectedCountry, setSelectedCountry] = useState(companyData.country || '');
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        validateForm();
    }, [selectedState, companyData.city, companyData.fullAddress, selectedSegment, selectedSubsegment, selectedCountry, typeID]);

    const validateForm = () => {
        if (typeID === 'CNPJ') {
            setIsFormValid(
                selectedState !== '' &&
                companyData.city !== '' &&
                companyData.fullAddress !== '' &&
                selectedSegment !== '' &&
                selectedSubsegment !== '' &&
                selectedCountry !== ''
            );
        } else {
            setIsFormValid(
                selectedSegment !== '' &&
                selectedSubsegment !== '' &&
                selectedCountry !== ''
            );
        }
    };

    const handleStateChange = (state) => {
        setSelectedState(state);
        setCompanyData(prevData => ({
            ...prevData,
            state: state
        }));
    };

    const handleSegmentChange = (segment) => {
        setSelectedSegment(segment);
        if (typeof onSegmentSelect === 'function') {
            onSegmentSelect(segment);
        }
    };

    const handleSubSegmentChange = (subsegment) => {
        setSelectedSubsegment(subsegment);
        if (typeof onSubSegmentSelect === 'function') {
            onSubSegmentSelect(subsegment);
        }
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setCompanyData(prevData => ({
            ...prevData,
            country: country
        }));
    };

    const handleNextClick = () => {
        if (isFormValid) {
            onNext();
        }
    };

    return (
        <section className='center-comp'>
            <img src={Ico} className='Ico' alt='Icon' />
            <h4 className='icoText'>{t('register')}</h4>
            <div>
                <form className='formdataRegisterInpt formLarge'>
                    <label className='lbInpt'>{t('segment')}</label>
                    <SegmentSelect
                        onSegmentSelect={handleSegmentChange}
                    />

                    <label className='lbInpt'>{t('subsegment')}</label>
                    <SubSegmentSelect
                        selectedSegment={selectedSegment}
                        resetSelection={selectedSegment !== companyData.segment}
                        onSubSegmentSelect={handleSubSegmentChange}
                    />

                    <label className='lbInpt'>{t('country')}</label>
                    <CountrySelect onCountryChange={handleCountryChange} />

                    {typeID === 'CNPJ' && (
                        <div className='addressLine'>
                            <label className='lbInpt gridStart'>{t('state')}</label>
                            <label className='lbInpt gridEnd'>{t('city')}</label>

                            <StateSelect
                                className="gridStart"
                                onChange={handleStateChange}
                                selectedState={selectedState}
                            />
                            <CitySelect
                                className="gridEnd"
                                selectedState={selectedState}
                                setCompanyData={setCompanyData}
                            />
                        </div>
                    )}

                    <label className='lbInpt'>{t('full_address')}</label>
                    <input
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder={t('zip_street_neighborhood')}
                        type='text'
                        name='fullAddress'
                        value={companyData.fullAddress || ''}
                        onChange={(e) => setCompanyData(prevData => ({
                            ...prevData,
                            fullAddress: e.target.value
                        }))}
                    />
                </form>

                <button
                    className='action'
                    onClick={handleNextClick}
                    disabled={!isFormValid}
                >
                    <p>{t('next')}</p>
                </button>
            </div>
        </section>
    );
}

export default CompanyComplet;
