import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'lucide-react';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../tailwind.css';
import '../Item/itens.css';
import LanguageSelector from '../Translate/button';

function SegmentSelect({ onSegmentSelect }) {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(t('select'));
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#C5C5C5');
    const [fontWeight, setFontWeight] = useState(400);

    // Obtém os segmentos traduzidos do JSON
    const segments = t('segments', { returnObjects: true }) || [];

    // Função para lidar com a seleção de um segmento
    const handleOptionSelect = (segment) => {
        setSelectedOption(segment);  // Define o segmento selecionado
        setIsOptionsVisible(false);  // Fecha o dropdown
        setSelectedColor('black');   // Muda a cor do texto para preto
        setFontWeight(500);          // Aumenta o peso da fonte para 500

        // Chama a função de callback para passar o segmento selecionado
        if (onSegmentSelect) {
            onSegmentSelect(segment);
        }
    };

    return (
        <section id='selectBox' style={{ position: 'relative' }}>
            <section id='category-select'>
                <input
                    type='checkbox'
                    className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                    placeholder={t('select')}
                    id='options-view-button'
                    checked={isOptionsVisible}
                    onChange={() => setIsOptionsVisible(!isOptionsVisible)}
                />
                <div id='selectButton'>
                    <div
                        id='selectedValue'
                        style={{ color: selectedColor, fontWeight: fontWeight }}
                    >
                        {selectedOption}
                    </div>
                    <div id='chevrons'>
                        <ChevronDown size={20} id='down' style={{ display: isOptionsVisible ? 'none' : 'block' }} />
                        <ChevronUp size={20} id='up' style={{ display: isOptionsVisible ? 'block' : 'none' }} />
                    </div>
                </div>
            </section>
            <ul
                id="options"
                style={{
                    display: isOptionsVisible ? 'block' : 'none',
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    zIndex: 10,
                    backgroundColor: '#F3F3F3',
                    width: '100%',
                    maxHeight: '150px',
                    overflowY: 'auto', // Adiciona o scroll
                }}
            >
                {segments.map((segment, index) => (
                    <li className="option" key={index} onClick={() => handleOptionSelect(segment)}>
                        <input type='radio' name='category' value={segment} checked={selectedOption === segment} readOnly />
                        <span className='label'>{segment}</span>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default SegmentSelect;
