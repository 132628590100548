import React, { useEffect, useState } from 'react'; 
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; 
import '../../Components/Buyer/product.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import MenuRounds from '../Layout/menu';
import FooterRounds from '../Layout/footer';

function Product() {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate(); 

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`https://api.father.srv.br/api/v2/products/product/${productId}`);
                setProduct(response.data);

                // Salvar o companyID no localStorage para uso posterior
                localStorage.setItem('selectedCompanyID', response.data.companyID);
            } catch (err) {
                setError('Erro ao carregar o produto: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [productId]);

    const scheduleMeeting = async () => {
        try {
            const roundKey = product.roundKey; 
            const companyID = product.companyID;
            const productID = product._id;  

            if (!companyID || typeof roundKey !== 'string') {
                alert('CompanyID ou RoundKey inválidos. Verifique o valor.');
                return;
            }

            const response = await axios.post('https://api.father.srv.br/api/v2/agends/create-trade-calendar', {
                companyID: companyID,
                roundKey: roundKey,
            });

            if (response.status === 200 || response.status === 201) {
                // Redirecione passando o productID no state
                navigate('/rounds/agend', { state: { productID } });
            } else {
                alert('Erro ao criar o calendário de negócios.');
            }
        } catch (err) {
            alert('Ainda não há uma agenda para essa rodada.');
            console.error(err);
        }
    };

    if (loading) {
        return <p>Carregando produto...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!product) {
        return <p>Produto não encontrado.</p>;
    }

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <section>
            <section className='homeContent'>
                <MenuRounds />
                <div className={`boxProductItem ${isExpanded ? 'expanded' : ''}`}>
                    <div className='productPhotos'>
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            loop={true}
                            modules={[Navigation, Pagination]}
                            style={{ height: '30rem', width: '30rem', borderRadius: '10px' }}
                        >
                            {product.photoUrls && product.photoUrls.length > 0 ? (
                                product.photoUrls.map((url, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            src={url}
                                            alt={`Imagem ${index + 1} do produto ${product.productName}`}
                                            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                        />
                                    </SwiperSlide>
                                ))
                            ) : (
                                <SwiperSlide>
                                    <p>Sem fotos disponíveis</p>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>

                    <div className='productInfosItem'>
                        <h1 className='marginProduct1'>{product.productName}</h1>
                        <label className='lbInpt marginProduct'>Descrição</label>
                        <p>
                            {isExpanded ? product.productDesc : `${product.productDesc.slice(0, 515)}...`}
                            {product.productDesc.length > 515 && (
                                <span onClick={toggleDescription} className='toggleDescription'>
                                    {isExpanded ? ' Ler menos' : ' Ler mais'}
                                </span>
                            )}
                        </p>
                        <label className='lbInpt marginProduct1'>Valor</label>
                        <h2>{product.cashType} {product.unitValue}</h2>
                        <label className='lbInpt marginProduct1'>Origem</label>
                        <p>{product.state}{product.country}</p>
                        <button className='action marginProduct' onClick={scheduleMeeting}>
                            <p id='noneNorm'>Agendar reunião</p>
                        </button>
                    </div>
                </div>
            </section>
            <FooterRounds/>
        </section>
    );
}

export default Product;
