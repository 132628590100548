import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Ico from '../../assets/Lfather.png';
import '../../../../tailwind.css';
import { Link, useNavigate } from 'react-router-dom'; 

function TypeSelect({ onSubmit, registerServiceControl }) {
  const { t } = useTranslation();
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (type) => {
    setSelectedButton(type);
  };

  const handleNextClick = () => {
    if (selectedButton) {
      const typeID = selectedButton === 'supplier' ? 'CNPJ' : null;

      if (registerServiceControl && typeof registerServiceControl.setTypeID === 'function') {
        registerServiceControl.setTypeID(typeID); // Atualiza o typeID globalmente
      } else {
        console.error("registerServiceControl não contém setTypeID ou não está definido corretamente");
      }

      if (typeof onSubmit === 'function') {
        onSubmit(selectedButton, typeID);
      }
    } else {
      console.error("Nenhum tipo selecionado");
    }
  };

  return (
    <section className='center-comp'>
      <img src={Ico} className='Ico' alt='Icon' />
      <h4 className='icoText'>{t('register')}</h4>
      <div>
        <form className='formdataRegister'>
          <button
            type="button"
            className={selectedButton === 'supplier' ? 'selected' : ''}
            onClick={() => handleButtonClick('supplier')}
          >
            <p>{t('supplier')}</p>
          </button>
          <button
            type="button"
            className={selectedButton === 'buyer' ? 'selected' : ''}
            onClick={() => handleButtonClick('buyer')}
          >
            <p>{t('buyer')}</p>
          </button>
        </form>
        <button className='action' type='button' onClick={handleNextClick}>
          <p>{t('next')}</p>
        </button>
      </div>
      <div className='no-acc'>
        <p>
          Já tem sua conta?
          <Link to="/rounds/login">
            <strong>Clique aqui para entrar</strong>
          </Link>
        </p>
      </div>
    </section>
  );
}

export default TypeSelect;
