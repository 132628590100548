import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../Components/UserRounds/userRounds.css';
import RoundImage from '../UserRounds/photo.png';
import { useNavigate } from 'react-router-dom'; 

const UserRounds = () => {
    const [rounds, setRounds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); 

    const userEmail = localStorage.getItem('userEmail');

    useEffect(() => {
        if (!userEmail) {
            setError('No user email found.');
            setLoading(false);
            return;
        }

        const fetchUserRounds = async () => {
            try {
                const userResponse = await axios.get(`https://api.father.srv.br/api/v2/users/email/${userEmail}`);

                const userRounds = userResponse.data.rounds;

                if (!userRounds || userRounds.length === 0) {
                    setRounds([]);
                    setLoading(false);
                    return;
                }

                const roundDetailsPromises = userRounds.map(keyRound =>
                    axios.get(`https://api.father.srv.br/api/v2/rounds/keyRound/${keyRound}`)
                );
                const roundDetailsResponses = await Promise.all(roundDetailsPromises);
                setRounds(roundDetailsResponses.map(response => response.data));
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserRounds();
    }, [userEmail]);

    const handleCardClick = (round) => {
        if (!round) {
            setError('Round não encontrado');
            return;
        }
        navigate(`/rounds/round/${round.keyRound}`, { state: { round } });
    };

    if (loading) return <p>Carregando...</p>;
    if (error) return <p>Erro: {error}</p>;

    return (
        <div className='gridCards'>
            {rounds.length === 0 ? (
                <p>Nenhum round encontrado.</p>
            ) : (
                rounds.map(round => (
                    <div key={round.keyRound} className='cardRounds'>
                        <div className='roundLine'>
                            <div className={`statusRound ${round.statusRound}`}>
                                {round.statusRound === 'active' ? 'Ativo' : round.statusRound === 'coming' ? 'Em breve' : 'Arquivado'}
                            </div>
                        </div>
                        <div className='roundItem' onClick={() => handleCardClick(round)}>
                            <label className='labelRound'>Rodada</label>
                            <p className='descRound'>{round.nameRound}</p>
                            <label className='labelRound'>Local</label>
                            <p className='descRound'>{round.localRound}</p>
                            <label className='labelRound'>Data</label>
                            <p className='descRound'>{new Date(round.startDate).toLocaleDateString('pt-BR')} à {new Date(round.endDate).toLocaleDateString('pt-BR')}</p>
                        </div>
                        <img src={RoundImage} alt="Round" />
                    </div>
                ))
            )}
        </div>
    );
};

export default UserRounds;
