import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'lucide-react';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../tailwind.css';
import '../Item/itens.css';
import '../../../../services/registerServiceControl';

function DocumentTypeSelect({ onDocumentTypeChange }) {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(t('select'));
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#C5C5C5');
    const [fontWeight, setFontWeight] = useState(400);

    const handleOptionSelect = (label) => {
        setSelectedOption(label);
        setIsOptionsVisible(false);
        setSelectedColor('black');
        setFontWeight(500);
        onDocumentTypeChange(label); // Passa o valor selecionado para a função do props
    };

    return (
        <section id='selectBox' style={{ position: 'relative' }}>
            <section id='category-select'>
                <input
                    type='checkbox'
                    className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                    placeholder={t('select')}
                    id='options-view-button'
                    checked={isOptionsVisible}
                    onChange={() => setIsOptionsVisible(!isOptionsVisible)}
                />
                <div id='selectButton'>
                    <div
                        id='selectedValue'
                        style={{ color: selectedColor, fontWeight: fontWeight }}
                    >
                        {selectedOption}
                    </div>
                    <div id='chevrons'>
                        <ChevronDown size={20} id='down' style={{ display: isOptionsVisible ? 'none' : 'block' }} />
                        <ChevronUp size={20} id='up' style={{ display: isOptionsVisible ? 'block' : 'none' }} />
                    </div>
                </div>
            </section>
            <ul
                id="options"
                style={{
                    display: isOptionsVisible ? 'block' : 'none',
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    zIndex: 10,
                    backgroundColor: '#F3F3F3',
                    width: '100%',
                }}
            >
                <li className="option" onClick={() => handleOptionSelect('CNPJ')}>
                    <input type='radio' name='category' value='CNPJ' />
                    <span className='label'>CNPJ</span>
                </li>
                <li className="option" onClick={() => handleOptionSelect('Tax ID')}>
                    <input type='radio' name='category' value='Tax ID' />
                    <span className='label'>Tax ID</span>
                </li>
                <li className="option" onClick={() => handleOptionSelect('RUC')}>
                    <input type='radio' name='category' value='RUC' />
                    <span className='label'>RUC</span>
                </li>
                <li className="option" onClick={() => handleOptionSelect('RUT')}>
                    <input type='radio' name='category' value='RUT' />
                    <span className='label'>RUT</span>
                </li>
                <li className="option" onClick={() => handleOptionSelect('NIT')}>
                    <input type='radio' name='category' value='NIT' />
                    <span className='label'>NIT</span>
                </li>
                <li className="option" onClick={() => handleOptionSelect('NIF')}>
                    <input type='radio' name='category' value='NIF' />
                    <span className='label'>NIF</span>
                </li>
            </ul>
        </section>
    );
}

export default DocumentTypeSelect;
