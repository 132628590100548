import React, { useState, useEffect } from 'react';
import Ico from '../../assets/Lfather.png';
import { Section } from 'lucide-react';
import { useTranslation } from 'react-i18next';


function BuyerComplet() {

    const { t } = useTranslation();

    return (
        <section>
            <img src={Ico} className='Ico' alt='Icon' />
            <h4 className='icoText'>{t('register')}</h4>
            <form className='formdataRegisterInpt formLarge'>
                <label className='lbInpt'>País</label>
                <input
                    className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                    placeholder={t('enter_country')}
                    type='text'
                />

                <label className='lbInpt'>Endereço completo</label>
                <input
                    className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                    placeholder={t('adress-complet')}
                    type='text'
                />

            </form>
            <button
                    className='action'
                >
                    <p>{t('next')}</p>
                </button>
        </section>
    )
}

export default BuyerComplet;