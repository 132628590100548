// App.js
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Questions from "./pages/Questions";
import Summary from "./pages/Summary";
import UserInfo from "./pages/UserInfo";
import Segment from "./pages/Segment";
import Loading from "./pages/Loading/loading";
import "./App.css";
import './i18n'; // <- importar o i18n configurado
import '../src/tailwind.css';

import Login from "./pages/Rounds/Pages/LoginRegister/Login/index";
import Register from './pages/Rounds/Pages/Register/index';
import Sucess from "./pages/Rounds/Pages/Sucess";
import KeyRounds from "./pages/Rounds/Pages/KeyRounds";
import HomeRounds from "./pages/Rounds/Pages/Home";
import ProtectedRoute from "./services/protectedRoute"; // Importe o ProtectedRoute
import Round from "./pages/Rounds/Pages/Round/round";
import NewProduct from "./pages/Rounds/Pages/newProduct/newProduct";
import BuyerData from "./pages/Rounds/Components/FormRegister/buyerData";
import BuyerComplet from "./pages/Rounds/Components/FormRegister/buyerComplet";
import Product from "./pages/Rounds/Components/Buyer/product";
import Agend from "./pages/Rounds/Components/Buyer/agend";
import Attention from "./pages/Manutenção/attention";
import Calendar from "./pages/Rounds/Pages/Calendar/calendar";



function App() {
  const [currentStep, setCurrentStep] = useState(1);
  const [userInfo, setUserInfo] = useState({ name: "", email: "", company: "", cnpj: "", loc: "" });
  const [segment, setSegment] = useState("");
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState("");

  useEffect(() => {
    if (currentStep === 4) {
      setTimeout(() => {
        setLoading(false);
        handleNextStep();
      }, 5000);
    }
  }, [currentStep]);

  function handleNextStep() {
    setCurrentStep(currentStep + 1);
  }

  function handlePrevStep() {
    setCurrentStep(currentStep - 1);
  }

  function handleSubmitUserInfo(user) {
    setUserInfo(user);
    handleNextStep();
  }

  function handleSegmentSelected(departament) {
    setSegment(departament);
    if (departament === "service") {
      setUserType("DECISOR");
    } else if (departament === "product") {
      setUserType("ADMINISTRATIVO");
    }
    handleNextStep();
  }

  function handleQuestionsAnswered(answer) {
    setAnswers(answer);
  }

  function getStepScreen() {
    switch (currentStep) {
      case 1: return <UserInfo onNextStep={handleNextStep} onSubmit={handleSubmitUserInfo} />;
      case 2: return <Segment onSelect={handleSegmentSelected} />;
      case 3: return <Questions segment={segment} onNextStep={handleNextStep} onPrevStep={handlePrevStep} onQuestionsAnswered={handleQuestionsAnswered} />;
      case 4: return loading ? <Loading /> : <Summary info={{ userInfo, segment: segment, answers }} />;
      case 5: return <Summary info={{ userInfo, segment: segment, answers, userType }} />;
      default: return <h1>Obrigado!</h1>;
    }
  }

  return (


  
      <Router>
      <Routes>
        <Route path='/' element={getStepScreen()} />
        <Route path='/rounds/login' element={<Login />} />
        <Route path='/rounds/register' element={<Register />} />
        <Route path='/rounds/sucess' element={<Sucess />} />
        <Route 
          path='/rounds/key' 
          element={
            <ProtectedRoute>
              <KeyRounds />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/rounds/home' 
          element={
            <ProtectedRoute>
              <HomeRounds />
            </ProtectedRoute>
          } 
        />
       <Route 
          path='/rounds/round/:keyRound' 
          element={
            <ProtectedRoute>
              <Round />
            </ProtectedRoute>
          } 
        />
         <Route 
          path='/rounds/round/:keyRound/newproduct' 
          element={
            <ProtectedRoute>
              <NewProduct />
            </ProtectedRoute>
          } 
        />
        <Route path="/product/:productId" element={<Product />} />  
        <Route 
          path='/rounds/agend' 
          element={
            <ProtectedRoute>
              <Agend />
            </ProtectedRoute>
          } 
        />
         <Route 
          path='/rounds/calendar' 
          element={
            <ProtectedRoute>
              <Calendar />
            </ProtectedRoute>
          } 
        />
        <Route path='/rounds/test' element={<BuyerComplet />} />
      </Routes>
    </Router>
   
  );
}

export default App;
