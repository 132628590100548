import logo from "../../assets/images/logo.png";
import "./segment.css";
import { useState, useEffect } from "react";

export default function Segment({ onSelect }) {
  useEffect(() => {
    // Define a cor de fundo quando o componente é montado
    document.body.style.backgroundColor = '#000000';

    // Reverte a cor de fundo quando o componente é desmontado
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []); // O array vazio [] assegura que o efeito seja executado apenas uma vez

  return (
    <div className="box-center-change">
      <div className="logo"><img src={logo} alt="Logo" /></div>
      <div className="line-title"><h1>Selecione seu segmento</h1></div>
      <div className="line-select">
        <div className="card-1" onClick={() => onSelect("product")}>
          <svg width="38" height="38" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="change">
            <mask id="mask0_1_7" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1_7)">
              <path d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V8C3 7.45 3.19583 6.97917 3.5875 6.5875C3.97917 6.19583 4.45 6 5 6H7C7 4.61667 7.4875 3.4375 8.4625 2.4625C9.4375 1.4875 10.6167 1 12 1C13.3833 1 14.5625 1.4875 15.5375 2.4625C16.5125 3.4375 17 4.61667 17 6H19C19.55 6 20.0208 6.19583 20.4125 6.5875C20.8042 6.97917 21 7.45 21 8V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM9 6H15C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6ZM11.975 14C13.2083 14 14.325 13.5917 15.325 12.775C16.325 11.9583 16.8167 11.0417 16.8 10.025C16.8 9.74167 16.7083 9.5 16.525 9.3C16.3417 9.1 16.1083 9 15.825 9C15.5917 9 15.3833 9.075 15.2 9.225C15.0167 9.375 14.8833 9.6 14.8 9.9C14.6167 10.5333 14.2583 11.0417 13.725 11.425C13.1917 11.8083 12.6083 12 11.975 12C11.3417 12 10.7542 11.8083 10.2125 11.425C9.67083 11.0417 9.31667 10.5333 9.15 9.9C9.06667 9.58333 8.94167 9.35417 8.775 9.2125C8.60833 9.07083 8.40833 9 8.175 9C7.89167 9 7.65417 9.1 7.4625 9.3C7.27083 9.5 7.175 9.74167 7.175 10.025C7.175 11.0417 7.66667 11.9583 8.65 12.775C9.63333 13.5917 10.7417 14 11.975 14Z" />
            </g>
          </svg>
          <h1>Produto</h1>
        </div>

        <div className="card-2" onClick={() => onSelect("service")}>
          <svg width="38" height="38" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="change">
            <mask id="mask0_1_19" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1_19)">
              <path d="M11 21.725L4 17.7C3.68333 17.5167 3.4375 17.275 3.2625 16.975C3.0875 16.675 3 16.3417 3 15.975V8.025C3 7.65833 3.0875 7.325 3.2625 7.025C3.4375 6.725 3.68333 6.48333 4 6.3L11 2.275C11.3167 2.09167 11.65 2 12 2C12.35 2 12.6833 2.09167 13 2.275L20 6.3C20.3167 6.48333 20.5625 6.725 20.7375 7.025C20.9125 7.325 21 7.65833 21 8.025V15.975C21 16.3417 20.9125 16.675 20.7375 16.975C20.5625 17.275 20.3167 17.5167 20 17.7L13 21.725C12.6833 21.9083 12.35 22 12 22C11.65 22 11.3167 21.9083 11 21.725ZM11 12.575V19.425L12 20L13 19.425V12.575L19 9.1V8.05L17.925 7.425L12 10.85L6.075 7.425L5 8.05V9.1L11 12.575Z" />
            </g>
          </svg>
          <h1>Serviço</h1>
        </div>
      </div>
    </div>
  );
}
