import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LanguageSelector from '../../Components/Translate/button';
import Ico from '../../../../assets/images/ico.png';
import LogoutButton from '../Logout/logoutButton';
import '../../../../tailwind.css';
import '../Layout/menu.css';

function MenuRounds() {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <section className='menuRounds'>

            <div className='leftMenu'>
                <img className='logoMenu' src={Ico} alt="Logo" />
            </div>
            <div className='hamburger' onClick={toggleMenu}>
                <span className='hamburgerIcon'>
                    <svg width="33" height="33" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.7891 10.0237H7.78906" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M21.7891 6.02368H3.78906" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M21.7891 14.0237H3.78906" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M21.7891 18.0237H7.78906" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg></span>
            </div>
            <div className={`menuItems ${menuOpen ? 'show' : ''}`}>
                <div className='leftMenuItems'>
                    <Link id='link' to='/rounds/home'><p className='menuLeftSpace'>Início</p></Link>
                   <Link id='link' to='/rounds/calendar'><p>Minha agenda</p> </Link>
                </div>

                <div className='rightMenuItems'>
                    <Link to='/rounds/key'><div className='ingressRound'>Ingressar em uma rodada</div></Link>
                    <LanguageSelector className='marginR' />
                    <LogoutButton />
                </div>
            </div>
        </section>
    );
}

export default MenuRounds;
