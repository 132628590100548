// sessionService.js
const USER_KEY = 'userEmail';
const TOKEN_KEY = 'userToken';

const login = (email, token) => {
    localStorage.setItem(USER_KEY, email);
    localStorage.setItem(TOKEN_KEY, token);
};

const logout = () => {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
};

const getUser = () => {
    return localStorage.getItem(USER_KEY);
};

const getToken = () => {
    return localStorage.getItem(TOKEN_KEY);
};

const isAuthenticated = () => {
    return !!localStorage.getItem(TOKEN_KEY);
};

export default {
    login,
    logout,
    getUser,
    getToken,
    isAuthenticated
};
