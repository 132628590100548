import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'lucide-react';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../tailwind.css';
import '../Item/itens.css';

function PositionSelect({ onChange }) {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(t('select'));
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#C5C5C5');
    const [fontWeight, setFontWeight] = useState(400);

    const handleOptionSelect = (label) => {
        setSelectedOption(label);
        setIsOptionsVisible(false);
        setSelectedColor('black');
        setFontWeight(500);
        if (onChange) onChange(label); // Chama a função onChange passando a opção selecionada
    };

    const positions = t('positions', { returnObjects: true }) || [];

    return (
        <section id='selectBox' style={{ position: 'relative' }}>
            <section id='category-select'>
                <input
                    type='checkbox'
                    className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                    placeholder={t('select')}
                    id='options-view-button'
                    checked={isOptionsVisible}
                    onChange={() => setIsOptionsVisible(!isOptionsVisible)}
                />
                <div id='selectButton'>
                    <div
                        id='selectedValue'
                        style={{ color: selectedColor, fontWeight: fontWeight }}
                    >
                        {selectedOption}
                    </div>
                    <div id='chevrons'>
                        <ChevronDown size={20} id='down' style={{ display: isOptionsVisible ? 'none' : 'block' }} />
                        <ChevronUp size={20} id='up' style={{ display: isOptionsVisible ? 'block' : 'none' }} />
                    </div>
                </div>
            </section>
            <ul
                id="options"
                style={{
                    display: isOptionsVisible ? 'block' : 'none',
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    zIndex: 10,
                    backgroundColor: '#F3F3F3',
                    width: '100%',
                    maxHeight: '180px',
                    overflowY: 'auto',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px',
                }}
            >
                {positions.map((position, index) => (
                    <li className="option" key={index} onClick={() => handleOptionSelect(position)}>
                        <input type='radio' name='category' value={position} data-label={position} />
                        <span className='label'>{position}</span>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default PositionSelect;
