import React from 'react';
import Banner from '../../Components/Banner/banner';
import '../Register/Register.css';
import TranslateButton from '../../Components/Translate/button';
import RegisterService from '../../../../services/registerServiceControl';

function Register() {
  return (
    <section className='LoginPage'>
      <section>
        <Banner />
      </section>
      <section className='FormCenter'>
        <div className='translate'><TranslateButton /></div>
        <div className='FormLogin'>
          <RegisterService />
        </div>
      </section>
    </section>
  );
}

export default Register;
