import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importa o useNavigate
import Ico from '../../assets/Lfather.png';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../../src/tailwind.css';

function PasswordCreate({ companyID, contactData, emailExists, companyIDExists, userType, companyData }) {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Inicializa o hook useNavigate
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [loading, setLoading] = useState(false);

    console.log(companyID, companyData, contactData, userType, emailExists, companyIDExists);

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            setPasswordsMatch(false);
            return;
        }
        setLoading(true);

        try {
            // 1 - Se o CompanyID não existir na base, cria a empresa
            if (!companyIDExists) {
                await axios.post('https://api.father.srv.br/api/v2/companies', {
                    companyInfo: {
                        companyID: companyID, // Inclui o CompanyID da empresa
                        companyName: companyData.companyName,
                        socialNameCompany: companyData.corporateName,
                        socialClass: companyData.fiscalClass,
                        Segment: companyData.segment,
                        SubSegment: companyData.subsegment,
                        country: companyData.country,
                        City: companyData.city,
                        State: companyData.state,
                        Address: companyData.fullAddress,
                        typeID: companyData.typeID // Inclui o typeID
                    }
                });
            }

            // 2 - Se o email não existir, cria o contato
            if (!emailExists) {
                await axios.post('https://api.father.srv.br/api/v2/contacts', {
                    contactInfo: {
                        ...contactData, // Inclui os dados de contato
                        companyID: companyID, // Inclui o CompanyID associado ao contato
                    }
                });
            }

            // 3 - Cria o usuário
            await axios.post('https://api.father.srv.br/api/v2/users', {
                companyID: companyID, // Inclui o CompanyID do usuário
                email: contactData.email, // E-mail do usuário
                senha: password, // Senha escolhida
                type: userType // Tipo de usuário (fornecedor ou comprador)
            });

            // Redireciona para a rota /rounds/login
            navigate('/rounds/login');
        } catch (error) {
            console.error('Erro ao criar o usuário:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className='center-comp'>
            <img src={Ico} className='Ico' alt='Icon' />
            <h4 className='icoText'>Criar senha</h4>
            <div>
                <form className='formdataRegisterInpt'>
                    <label className='lbInpt'>{t('password')}</label>
                    <input
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder={t('enter_password')}
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <label className='lbInpt'>{t('Confirme a senha')}</label>
                    <input
                        className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                        placeholder='Digite a senha novamente'
                        type='password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {!passwordsMatch && <p className="validInfos">As senhas não são iguais.</p>}
                </form>
                <button className='action' onClick={handleSubmit} disabled={loading}>
                    <p>{t('register')}</p>
                </button>
                {loading && (
                    <div className='loading-circle'></div>
                )}
            </div>
        </section>
    );
}

export default PasswordCreate;
