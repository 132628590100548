import logo from "../../../assets/images/logo.png"
import "./question.css"
import { useState, useEffect } from "react";

export default function Question({ question, onAltSelected, onPrev}) {
  const [hoverId, setHoverId] = useState(null);

  const handleMouseEnter = (id) => {
    setHoverId(id);
  };

  const handleMouseLeave = () => {
    setHoverId(null);
  };

  const handleClick = (alt) => {
    onAltSelected({ questionId: question.id, selectedAlt: alt });
    setHoverId(null);
  };

  useEffect(() => {
    // Define a cor de fundo quando o componente é montado
    document.body.style.backgroundColor = '#000000';

    // Reverte a cor de fundo quando o componente é desmontado
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []); // O array vazio [] assegura que o efeito seja executado apenas uma vez

  return (
    <section className="box-center">
      <div className="logo">
        <img src={logo} alt="Father Logo" />
      </div>
      <div className="box-qts">
        <div className="box-content">
          <div className="box-title"><h1>{question.title}</h1></div>
          <div className="box-alternativas">
            <ol>
              {question.alts.map(alt =>
                <li key={alt.id} 
                    className={`alter ${hoverId === alt.id ? 'hover' : ''}`} 
                    onMouseEnter={() => handleMouseEnter(alt.id)} 
                    onMouseLeave={handleMouseLeave} 
                    onClick={() => handleClick(alt)}>
                  {alt.title}
                </li>
              )}
            </ol>
          </div>
        </div>
      </div>
      <button className="back" onClick={onPrev}><h1>Voltar</h1></button>
    </section >
  )
}
