// src/pages/HomeRounds/HomeRounds.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Home/home.css';
import '../../../../tailwind.css';
import MenuRounds from '../../Components/Layout/menu';
import UserRounds from '../../Components/UserRounds/userRounds';
import ProtectedRoute from '../../../../services/protectedRoute';
import FooterRounds from '../../Components/Layout/footer';


function HomeRounds() {
    const { t } = useTranslation();

    return (
        <section>
        <section className='homeContent'>
            <MenuRounds />
            <UserRounds /> {/* Adicionando o componente UserRounds */}
            
        </section>
        <FooterRounds/>
        </section>
       
    );
}

export default HomeRounds;
