// LogoutButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import sessionService from '../../../../services/sessionService';
import '../Logout/logout.css'

const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionService.logout();
        navigate('/rounds/login');
    };

    return (
        <div className='logout' onClick={handleLogout}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.9375 21.5815H5.9375C5.40707 21.5815 4.89836 21.3708 4.52329 20.9958C4.14821 20.6207 3.9375 20.112 3.9375 19.5815V5.58154C3.9375 5.05111 4.14821 4.5424 4.52329 4.16733C4.89836 3.79226 5.40707 3.58154 5.9375 3.58154H9.9375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.9375 17.5815L21.9375 12.5815L16.9375 7.58154" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21.9375 12.5815H9.9375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    );
};

export default LogoutButton;
