import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Ico from '../../assets/Lfather.png';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../../src/tailwind.css';
import axios from 'axios';

const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

function CheckMail({ onNext, setContactData, setCurrentStep, setEmailExists }) {
    const { t } = useTranslation();
    const [emailInput, setEmailInput] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(''); // Adiciona um estado para a mensagem

    const handleNextClick = async () => {
        const isValid = isValidEmail(emailInput);
        setIsEmailValid(isValid);
        if (isValid) {
            setLoading(true);

            try {
                const response = await axios.get(`https://api.father.srv.br/api/v2/contacts/check-email/${emailInput}`);
                const { contactExists, userExists } = response.data;

                if (userExists) {
                    // Se o email existir na coleção de usuários, exibe uma mensagem
                    setMessage('Já existe um usuário com este email.');
                } else if (contactExists) {
                    // Se o email existir na coleção de contatos, define currentStep como 6
                    setEmailExists(true);
                    setCurrentStep(6); // Vai para a etapa de criação de senha
                } else {
                    // Se o email não existir em ambas as coleções
                    setContactData(prevData => ({
                        ...prevData,
                        email: emailInput
                    }));
                    setEmailExists(false);
                    setCurrentStep(5); // Vai para a etapa de criação de dados de contato
                }
            } catch (error) {
                console.error('Erro ao verificar o e-mail:', error);
                setIsEmailValid(false);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleInputChange = (e) => {
        const trimmedEmail = e.target.value.trim();
        setEmailInput(trimmedEmail);
        setIsEmailValid(true);
        setMessage(''); // Limpa a mensagem ao mudar o email
    };

    return (
        <section className='center-comp'>
            <img src={Ico} className='Ico' alt='Icon' />
            <h4 className='icoText'>{t('register')}</h4>
            <div>
                <form className='formdataRegisterInpt'>
                    <label className='lbInpt'>{t('email')}</label>
                    <input
                        className={`bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert ${!isEmailValid ? 'border-red-500' : ''}`}
                        placeholder={t('enter_email')}
                        type='text'
                        value={emailInput}
                        onChange={handleInputChange}
                    />
                    {!isEmailValid && <p className="validInfos">{t('invalid_email')}</p>}
                    {message && <p className="validInfos">{message}</p>} {/* Exibe a mensagem, se houver */}
                    <div className='termInfo'><p>{t('terms_of_use')}</p></div>
                </form>
                <button className='action' onClick={handleNextClick}>
                    <p>{t('next')}</p>
                </button>
                {loading && (
                    <div className='loading-circle'></div>
                )}
            </div>
        </section>
    );
}

export default CheckMail;
