import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'lucide-react';
import '../FormLogin/formLogin.css';
import '../FormRegister/formRegister.css';
import '../../../../tailwind.css';
import '../Item/itens.css';

function FiscalClassSelect({ selectedFiscalClass, onChange }) {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(t('select'));
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#C5C5C5'); // Cor inicial
    const [fontWeight, setFontWeight] = useState(400); // Peso da fonte inicial

    useEffect(() => {
        if (selectedFiscalClass) {
            setSelectedOption(selectedFiscalClass);
            setSelectedColor('black');
            setFontWeight(500);
        }
    }, [selectedFiscalClass]);

    const handleOptionSelect = (label) => {
        setSelectedOption(label);
        setIsOptionsVisible(false);
        setSelectedColor('black'); // Muda a cor para preto
        setFontWeight(500); // Aumenta o peso da fonte para 500
        onChange(label); // Passa o valor selecionado para o pai
    };

    return (
        <section id='selectBox' style={{ position: 'relative' }}>
            <section id='category-select'>
                <input
                    type='checkbox'
                    className='bg-bgInpt font-inter text-input p-4 border-bdInpt focus:bg-bgInptSelc focus:border-bdInptSelc h-custom w-custom inputCert'
                    placeholder={t('select')}
                    id='options-view-button'
                    checked={isOptionsVisible}
                    onChange={() => setIsOptionsVisible(!isOptionsVisible)}
                />
                <div id='selectButton'>
                    <div
                        id='selectedValue'
                        style={{ color: selectedColor, fontWeight: fontWeight }}
                    >
                        {selectedOption}
                    </div>
                    <div id='chevrons'>
                        <ChevronDown size={20} id='down' style={{ display: isOptionsVisible ? 'none' : 'block' }} />
                        <ChevronUp size={20} id='up' style={{ display: isOptionsVisible ? 'block' : 'none' }} />
                    </div>
                </div>
            </section>
            <ul
                id="options"
                style={{
                    display: isOptionsVisible ? 'block' : 'none',
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    zIndex: 10,
                    backgroundColor: '#F3F3F3',
                    width: '100%',
                }}
            >
                <li className="option" onClick={() => handleOptionSelect('Simples Nacional')}>
                    <input type='radio' name='category' value='Simples Nacional' data-label="Simples Nacional" />
                    <span className='label'>Simples Nacional</span>
                </li>
                <li className="option" onClick={() => handleOptionSelect('Lucro presumido')}>
                    <input type='radio' name='category' value='Lucro presumido' data-label="Lucro presumido" />
                    <span className='label'>Lucro presumido</span>
                </li>
                <li className="option" onClick={() => handleOptionSelect('Lucro real')}>
                    <input type='radio' name='category' value='Lucro real' data-label="Lucro real" />
                    <span className='label'>Lucro real</span>
                </li>
            </ul>
        </section>
    );
}

export default FiscalClassSelect;
