// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import sessionService from './sessionService';

const ProtectedRoute = ({ children }) => {
    if (!sessionService.isAuthenticated()) {
        return <Navigate to="/rounds/login" />;
    }

    return children;
};

export default ProtectedRoute;
