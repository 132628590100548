import './loading.css'
import loadingGif from '../../assets/images/loading-father-comp-v2.gif';


const Loading=()=>{
  return(
    <div id="loader-container">
      <img src={loadingGif} alt="Loading..." />
    </div>
  
  )
}
export default Loading;