import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import MenuRounds from '../../Components/Layout/menu';
import ProductsGrid from '../../Components/Supplier/productsGrid'; 
import MarketPlace from '../../Components/Buyer/marketPlace'; 
import '../Round/round.css';
import FooterRounds from '../../Components/Layout/footer';

function Round() {
    const location = useLocation();
    const [companyLocation, setCompanyLocation] = useState({ state: '', country: '' });
    const [userType, setUserType] = useState(null);
    const round = location.state?.round; 

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit' };
        return new Date(dateString).toLocaleDateString('pt-BR', options);
    };

    const getStatusName = (status) => {
        switch (status) {
            case 'active':
                return 'Ativo';
            case 'coming':
                return 'Em breve';
            case 'inactive':
                return 'Arquivado';
            default:
                return 'Desconhecido';
        }
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'active':
                return 'statusRound active';
            case 'coming':
                return 'statusRound coming';
            case 'inactive':
                return 'statusRound inactive';
            default:
                return 'statusRound';
        }
    };

    useEffect(() => {
        const userType = localStorage.getItem('userType');
        setUserType(userType);

        const fetchCompanyLocation = async () => {
            const userCompanyID = localStorage.getItem('userCompanyID');
            if (userCompanyID) {
                try {
                    const response = await axios.get('https://api.father.srv.br/api/v2/companies/location', {
                        params: { companyID: userCompanyID }
                    });
                    setCompanyLocation(response.data);
                } catch (err) {
                    console.error('Erro ao buscar localização da empresa:', err.message);
                }
            }
        };

        fetchCompanyLocation();
    }, []);

    if (!round) {
        return <p>Nenhum round selecionado.</p>;
    }

    return (
        <section>
            <section className='menuContent'>
                <MenuRounds />
                <div className='roundInfos'>
                    <div className='lineTitle'>
                        <h2>{round.nameRound}</h2>
                        <div className={getStatusClass(round.statusRound)}>
                            {getStatusName(round.statusRound)}
                        </div>
                    </div>
                    <div className='listInfo'>
                        <label className='labelRound'>Local</label>
                        <p className='descRound'>{round.localRound}</p>
                        <label className='labelRound'>Modalidade</label>
                        <p className='descRound'>{round.modelRound}</p>
                        <label className='labelRound'>Data</label>
                        <p className='descRound'>
                            {formatDate(round.startDate)} - {formatDate(round.endDate)}
                        </p>
                    </div>
                </div>
                {userType === 'supplier' ? (
                    <ProductsGrid roundKey={round.keyRound} companyLocation={companyLocation} />
                ) : userType === 'buyer' ? (
                    <MarketPlace roundKey={round.keyRound} companyLocation={companyLocation} />
                ) : (
                    <p>Tipo de usuário desconhecido.</p>
                )}
            </section>
            <FooterRounds/>
        </section>
    );
}

export default Round;
